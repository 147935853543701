import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as ExemptionActions from '../+state/exemption.actions';
import { ExemptionPartialState, ImagesData } from '../+state/exemption.reducer';
import * as ExemptionSelectors from '../+state/exemption.selectors';

@Component({
  selector: 'exemption-step3-container',
  templateUrl: './step3.container.html',
})
export class Step3Container {
  imagesData$: Observable<ImagesData>;
  isLoading$: Observable<boolean>;

  constructor(private store: Store<ExemptionPartialState>) {
    this.imagesData$ = this.store.pipe(
      select(ExemptionSelectors.getImagesData)
    );

    this.isLoading$ = this.store.pipe(select(ExemptionSelectors.getIsLoading));
  }

  updateImagesData(imagesData: ImagesData) {
    this.store.dispatch(ExemptionActions.imagesDataChanged({ imagesData }));
  }

  goToNextStep() {
    this.store.dispatch(ExemptionActions.step3Completed());
  }
}
