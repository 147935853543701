import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VehicleInfo } from '@frontend/shared/interfaces';
import { ServerTypes } from '@frontend/shared/models';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LanguagesService } from './languages.service';

@Injectable()
export class LookupService {
  private readonly dateReg = /^\d{4}-\d{2}-\d{2}$/;
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private router: Router,
    private languageService: LanguagesService
  ) {}

  private readonly lezVehicleClassValidateEndpoint = '/v1/vehicles/lezlogic/mz/validate';

  private get url(): string {
    return this.apiService.getCurentServer(ServerTypes.vclass);
  }

  public getVehicleInfo(vrmid: string, date: string = null): Observable<VehicleInfo> {
    let url = `${this.url}/v1/vehicles/lez/mz/${vrmid}`;
    if(!!date && date.match(this.dateReg)) url = `${url}?date=${date}`;
    return this.http
      .get<VehicleInfo>(url)
      .pipe(catchError(this.handleError()));
  }

  public isAllowedInLez(lezCriteria: any, checkDate: Date): Observable<lezCriteria> {
    const { country = null, particulateFilter = false, emissionStandard = null, category = null, gvw = 0, seats = 0, registrationDate = null, fuel = null } = lezCriteria;

    const payload = {
      Country: country?.toUpperCase(),
      Category: category,
      EmissionStandard: emissionStandard,
      RegistrationDate: registrationDate,
      Fuel: fuel,
      ParticulateFilter: particulateFilter,
      Veteran: false,
      Status: null,
      Seats: seats,
      GVW: gvw,
      Date: checkDate
    }
    
    return this.http.post<LezCheckResult>(`${this.url}${this.lezVehicleClassValidateEndpoint}`, payload)
      .pipe(
        map(validateInfo => {
          const { check, tariffCode, version, price, criteria, startDate, endDate } = validateInfo;
          return ({
            check,
            category,
            version,
            tariffCode,
            vehicle: '',
            status: null,
            registrationDate,
            amount: parseInt(price || '0', 10),
            isManuallySubmitted: true
          })
        })
      );
  }

  private handleError() {
    return (error: any): Observable<any> => {
      console.error(error);
      this.router.navigate([
        this.languageService.getCurrentLang().id,
        'lookup',
        'step1',
      ]);
      return of(error);
    };
  }
}

export interface lezCriteria {
  check: string,
  category?: string,
  version?: string,
  tariffCode?: string,
  vehicle?: string,
  status?: string,
  id?: string,
  registrationDate?: string,
  amount?: number
  isManuallySubmitted?: boolean;
}

export interface LezCheckResult {
  check: string;
  tariffCode?: string;
  version?: string;
  price: string;
  criteria: any;
  startDate: Date;
  endDate: Date;
}