import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiService } from "../services/api.service";
import { countriesEU } from "../models/countriesEU";
import { ServerTypes } from "../models/servers";
import { requests } from "../models/requests";

@Injectable()
export class DropdownService {
  private selectedItemType: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private itemType: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private selectionToDisplay: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );

  public $selectedItemType: Observable<any>;
  public $itemType: Observable<any>;
  public $selectionToDisplay: Observable<string>;

  constructor(private http: HttpClient, private apiService: ApiService) {
    this.$selectedItemType = this.selectedItemType.asObservable();
    this.$itemType = this.itemType.asObservable();
    this.$selectionToDisplay = this.selectionToDisplay.asObservable();
  }

  public updateSelectedItemType(type: any): void {
    this.selectedItemType.next(type);
  }

  public updateItemType(type: any): void {
    this.itemType.next(type);
  }

  public updateSelectionToDisplay(selection: string): void {
    this.selectionToDisplay.next(selection);
  }

  public isEUmember(targetCountry) {
    return countriesEU.some(
      (country) => country.id === targetCountry.countryCode
    );
  }

  public convertToDropItemFormat(item): any {
    return {
      Id: item.countryId,
      code: item.countryCode.toLowerCase(),
      name: item.countryName,
    };
  }
}
