import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { DropdownBaseComponent } from "../../../components/dropdown-base.component";
import { closest } from "../../closestFunction";
import { DropdownListType } from "../dropdown-list-type.enum";
import { DropdownSelectedItemTypes } from "../dropdown-selected-item-types.enum";
import { InnerDropService } from "../inner-drop.service";

@Component({
  selector: "pp-dropdown-base",
  templateUrl: "./dropdown-base.component.html",
  styleUrls: ["./dropdown-base.component.scss"],
  exportAs: "dropdown",
})
export class InnerDropdownBaseComponent
  extends DropdownBaseComponent
  implements AfterViewInit {
  @Input() disabled: boolean = false;
  @Output() public countriesLoaded: EventEmitter<any[]> = new EventEmitter<
    any[]
  >();
  @ViewChild("dropdown") public dropdown: NgbDropdown;

  constructor(
    private ds: InnerDropService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
    super(ds, DropdownSelectedItemTypes, DropdownListType);
  }

  public ngAfterViewInit() {
    this.subscriptions.add(
      this.state.subscribe((val) => {
        val
          ? this.renderer.addClass(closest(document.body, "html"), "no-scroll")
          : this.renderer.removeClass(
              closest(document.body, "html"),
              "no-scroll"
            );
      })
    );

    if (!this.props.dropItems.length) {
      this.subscriptions.add(
        this.ds.items$.subscribe((items) => {
          this.props.dropItems = items;
          this.cdr.detectChanges();
        })
      );
    }

    this.ds.updateSelectionToDisplay(this.props.selectedItem);
  }

  public openHandler(event): void {
    this.stateChanged(event);
    if (!this.props.dropItems.length) {
      this.subscriptions.add(
        this.ds.getCountries().subscribe((countries) => {
          this.ds.updateItems(this.ds.getEUCountries(countries));
          if (this.props.additionalDropItems) {
            this.countriesLoaded.emit(countries);
          }
        })
      );
    }
  }
}
