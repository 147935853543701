import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService, LanguagesService } from '@frontend/shared/services';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { takeWhile, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoadService } from '../services';
import { RedirectReasons } from '../models/redirect-reasons.enum';
import { requests, uam2Requests } from '../models/requests';
import { protectedPages } from '../models/protectedPages';

@Injectable()
export class AppAuthInterceptor implements HttpInterceptor {
  private loggedOut = false;
  private protectedRequests = [
    'token',
    'vehicles',
    'claims',
    'parkiapay.com/adyen',
    requests.exemptTransactons,
    'wallet',
    'uam',
    requests.addCard,
    requests.postPayment,
    uam2Requests.vehicles,
    uam2Requests.updateAccount,
    uam2Requests.getCardInfo,
    uam2Requests.accountInvoices,
    uam2Requests.passwordReset,
    uam2Requests.logOut,
    ...protectedPages,
  ];

  constructor(
    private auth: AuthService,
    private router: Router,
    private langService: LanguagesService,
    private loadService: LoadService
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.protectedRequests.some((item) => request.url.includes(item))) {
      request = request.clone({
        setHeaders: {
          Authorization:
            this.auth.getTokenType() + ` ${this.auth.getAuthToken()}`,
        },
      });
      return next.handle(request).pipe(
        catchError((err: HttpErrorResponse) => {
          switch (err.status) {
            case 401: {
              this.loadService.hide();
              this.loggedOut = false;
              const targetPage = this.router.url.includes('/pay/')
                ? ['pay', 'step1']
                : ['account', 'login'];
              this.auth
                .logout()
                .pipe(takeWhile(() => !this.loggedOut))
                .subscribe(() => {
                  this.loggedOut = true;
                });
              this.router.navigate(
                [this.langService.getCurrentLang().id, ...targetPage],
                {
                  state: {
                    reason: RedirectReasons.auth,
                  },
                }
              );
              return throwError(err);
            }
          }
          return throwError(err);
        })
      );
    }
    return next.handle(request);
  }
}
