import { Country, Multimedia } from '@frontend/shared/models';
import { Action, createReducer, on, UPDATE } from '@ngrx/store';
import * as ExemptionActions from './exemption.actions';

export const EXEMPTION_FEATURE_KEY = 'exemption';

export interface PersonalData {
  firstName: string;
  lastName: string;
  type: 'individual' | 'company';
  companyName?: string;
  address: string;
  postalCode: string;
  city: string;
  country: Country;
  email: string;
  mobileNumberPrefix: string;
  mobileNumber: string;
  termsAccepted: boolean;
}

export interface VehicleData {
  registrationNumber: string;
  make: string;
  model: string;
  length: string;
  weight: string;
  euroGroup: string;
  hasParticalFilter: boolean;
  emissionClass: string;
  fuelType: string;
  registrationDateDay: number;
  registrationDateMonth: number;
  registrationDateYear: number;
  country: Country;
}

export interface ImagesData {
  images: Multimedia[];
}

export const IMAGES_DATA_STATE_KEY = 'imagesData';

export interface State {
  personalData: PersonalData;
  vehicleData: VehicleData;
  imagesData: ImagesData;

  completedSteps: number[];
  currentStep: number;

  isLoading: boolean;
}

export interface ExemptionPartialState {
  readonly [EXEMPTION_FEATURE_KEY]: State;
}

export const initialState: State = {
  personalData: initialPersonalData(),
  vehicleData: initialVehicleData(),
  imagesData: initialImagesData(),
  completedSteps: [],
  currentStep: 1,
  isLoading: false,
};

const exemptionReducer = createReducer(
  initialState,
  on(ExemptionActions.personalDataChanged, (state, { personalData }) => ({
    ...state,
    personalData: { ...state.personalData, ...personalData },
  })),
  on(ExemptionActions.vehicleDataChanged, (state, { vehicleData }) => ({
    ...state,
    vehicleData: { ...state.vehicleData, ...vehicleData },
  })),
  on(ExemptionActions.imagesDataChanged, (state, { imagesData }) => ({
    ...state,
    imagesData: { ...state.imagesData, ...imagesData },
  })),
  // on(ExemptionActions.step1Completed, onStepCompleted(1, 2)),
  // on(ExemptionActions.step2Completed, onStepCompleted(2, 3)),

  on(ExemptionActions.step2Completed, (state) => ({ ...state, completedSteps: state.completedSteps.includes(2) ? state.completedSteps : [...state.completedSteps, 2], currentStep: 3,  isLoading: false })),  
  on(ExemptionActions.step1Completed, (state) => ({ ...state, completedSteps: state.completedSteps.includes(1) ? state.completedSteps : [...state.completedSteps, 1], currentStep: 2,  isLoading: false })),
  on(ExemptionActions.submitExemptionSuccess, (state) => ({ ...state, completedSteps: state.completedSteps.includes(3) ? state.completedSteps : [...state.completedSteps, 3], currentStep: 4,  isLoading: false })),




  //on(ExemptionActions.submitExemptionSuccess, onStepCompleted(3, 4)),
  on(ExemptionActions.exemptionFinish, (state) => ({
    ...initialState,
    currentStep: 1,
  })),
  on(ExemptionActions.setCurrentStep, (state, { currentStep }) => ({
    ...state,
    currentStep,
  })),
  on(ExemptionActions.addAnotherVehicle, (state) => ({
    ...state,
    vehicleData: initialVehicleData(),
    imagesData: initialImagesData(),
    currentStep: 2,
    completedSteps: [1],
  })),
  on(ExemptionActions.step3Completed, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(ExemptionActions.submitExemptionError, (state) => ({
    ...state,
    isLoading: false,
  }))
);

function initialImagesData(): ImagesData {
  return {
    images: [],
  };
}

function initialVehicleData(): VehicleData {
  return {
    registrationNumber: null,
    make: null,
    model: null,
    emissionClass: null,
    fuelType: null,
    registrationDateDay: null,
    registrationDateMonth: null,
    registrationDateYear: null,
    country: null,
    length: null,
    euroGroup: null,
    weight: null,
    hasParticalFilter: false
  };
}

function initialPersonalData(): PersonalData {
  return {
    firstName: null,
    lastName: null,
    type: 'individual',
    companyName: null,
    address: null,
    postalCode: null,
    city: null,
    country: null,
    email: null,
    mobileNumberPrefix: null,
    mobileNumber: null,
    termsAccepted: false,
  };
}

function onStepCompleted(stepNumber: number, nextStepNumber: number) {
  return (state: { completedSteps: number[] }) => ({
    ...state,
    completedSteps: state.completedSteps.includes(stepNumber)
      ? state.completedSteps
      : [...state.completedSteps, stepNumber],
    currentStep: nextStepNumber,
    isLoading: false,
  });
}

export function reducer(state: State | undefined, action: Action) {
  if (action.type === UPDATE) {
    // when loading the state for the first time always initialize it with initial state, and initialize rest with storage state
    return {
      ...initialState,
      ...state,
    };
  }
  return exemptionReducer(state, action);
}
