export interface SidebarLink {
  text: string;
  link: string;
  id: string;
}

export const sidebarLinks: SidebarLink[] = [
  {
    text: 'MENU_MYACCOUNT',
    link: 'myaccount',
    id: 'menu-myaccount',
  },
  {
    text: 'EPASS_MENU_SETTINGS',
    link: 'settings',
    id: 'menu-settings',
  },
  {
    text: 'MENU_VEHICLE',
    link: 'vehicles',
    id: 'menu-vehicles',
  },
  {
    text: 'MENU_CREDITCARD',
    link: 'card',
    id: 'menu-card',
  },
  {
    text: 'MENU_INVOICES',
    link: 'invoices',
    id: 'menu-invoices',
  },
];

