import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../services/api.service";
import { AccountInvoice } from "../models/account-invoice";
import { MonthlyInvoice } from "../pages/account/account-invoices/invoices-monthly/monthly-invoice";
import { AuthService } from "./auth.service";
import { PaymentResult } from "../interfaces/payment-result";
import { Receipt } from "../interfaces/receipt";
import { ServerTypes } from "../models/servers";
import { requests, uam2Requests } from "../models/requests";
import { RequestHeaders } from "../models/request-headers.enum";
import { tap } from "rxjs/operators";

@Injectable()
export class WalletService {
  private httpHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private auth: AuthService
  ) {
    this.httpHeaders = this.apiService.getHeaders(ServerTypes.uam2, 0);
  }

  public receipt: Receipt;

  private get fronturl(): string {
    return this.apiService.getCurentServer(ServerTypes.claims);
  }

  private get url(): string {
    return this.apiService.getCurentServer(ServerTypes.uam2);
  }

  public getCardInfo(): Observable<any> {
    return this.http.get(`${this.url}` + uam2Requests.getCardInfo, {
      headers: this.httpHeaders,
    });
  }

  public removeCard(card: string): Observable<any> {
    return this.http.delete(`${this.url}${uam2Requests.removeCard}/${card}`, {
      headers: this.httpHeaders,
    });
  }

  public addCard(card: any): Observable<any> {
    return this.http.post(`${this.url}` + uam2Requests.addCard, card, {
      headers: this.httpHeaders,
    });
  }

  public addCard2(card: any): Observable<any> {
    return this.http.post(`${this.fronturl}/v2/wallet/addcard/`, card, { headers: this.httpHeaders, });
  }

  public createAccount(account): Observable<any> {
    return this.http.post(`${this.url}` + requests.createAccount, account, {
      headers: this.httpHeaders,
    });
  }

  public autopaydeposit(body: object): Observable<PaymentResult> {
    return this.http
      .post<PaymentResult>(`${this.url}` + requests.accountPay, body, {
        headers: this.httpHeaders,
      });
    //  .pipe(tap(() => this.appInsights.trackPaymentData(body)));
  }

  public getAccountInvoices(): Observable<AccountInvoice[]> {
    return this.http.get<AccountInvoice[]>(
      `${this.url}` + uam2Requests.accountInvoices
    );
  }
  public getInvoiceCheck(): Observable<string> {
    return this.http.get<string>(`${this.url}/client/v1/invoices/check` );
  }
  

  public getMonthlyTransactions(invoiceObj: {
    reference: string;
    year: string;
    month: string;
  }): Observable<MonthlyInvoice[]> {
    return this.http.get<MonthlyInvoice[]>(
      `${this.url}${uam2Requests.invoiceDetail}/${invoiceObj.reference}/details`
    );
  }

  public payMonthly(reference: string): Observable<any> {
    return this.http.post(`${this.url}/client/v1/invoices/${reference}/details/pay`, null);
  }

  public getInvoicePDF(file: string): Observable<any> {
    const headers = new HttpHeaders().set(
      RequestHeaders.auth,
      this.auth.getTokenType() + ` ${this.auth.getAuthToken()}`
    );
    return this.http.get(`${this.url}${uam2Requests.invoicePDF}/${file}`, {
      headers,
      responseType: "blob",
    });
  }

  public getInvoicePDF_TS(file: string): Observable<any> { 
    const headers = new HttpHeaders().set(RequestHeaders.auth, this.auth.getTokenType() + ` ${this.auth.getAuthToken()}`);

    return this.http.get(`https://api-account-ts-pdf.epass24.com/client/v1/documents/${file}`, { headers, responseType: "blob" });
  }

  public getReceipt(puid: string): Observable<Receipt> {
    const headers = new HttpHeaders().set(RequestHeaders.auth, `${this.auth.getTokenType()} ${this.auth.getAuthToken()}`);

    return this.http.get<Receipt>(`${this.fronturl}/v2/receipt/epass24-${puid}`, { headers });
  }

  public getExemptTransactions(): Observable<MonthlyInvoice[]> {
    return this.http.get<MonthlyInvoice[]>(
      `${this.url}/${requests.exemptTransactons}/`
    );
  }
}
