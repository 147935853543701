import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguagesService } from '@frontend/shared/services';
import { translateToLink } from '@frontend/shared/helpers';

@Component({
  selector: 'frontend-epass24-layout',
  templateUrl: './epass24-layout.component.html',
  styleUrls: ['./epass24-layout.component.scss'],
})
export class Epass24LayoutComponent implements OnInit {
  menuItems: Array<{ text: string; href: string }> = [
    {
      text: 'HEADER_MENU_ABOUT',
      href: 'HEADER_MENU_ABOUT_LINK',
    },
    {
      text: 'HEADER_MENU_CHARGES',
      href: 'HEADER_MENU_CHARGES_LINK',
    },
    {
      text: 'HEADER_MENU_OPERATORS',
      href: 'HEADER_MENU_OPERATORS_LINK',
    },
    {
      text: 'HEADER_MENU_SUPPORT',
      href: 'HEADER_MENU_SUPPORT_LINK',
    },
  ];
  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private languageService: LanguagesService,
    private router: Router
  ) {}

   ngOnInit(): void {

   }


  goToRoute(route: string) {
    this.router.navigate([
      translateToLink(route, this.languageService, this.translateService),
    ]);
  }
}
