export enum CoreStorageEnum {
  currency = "pin_currency",
  tokenName = "access_token",
  tokenType = "token_type",
  refreshTokenName = "refresh_token",
  tokenExpiresName = "expires_in",
  tokenLastCheck = "token_lastcheck",
  clientId = "clientId",
  username = "username",
  testId = "testId",
  userType = "userType",
}
