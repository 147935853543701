import {Component} from '@angular/core';
import {InnerDropService} from '../../inner-drop.service';
import {SelectionToDisplaySubscriber} from '../selection-to-display-subscriber';

@Component({
  selector: 'pp-selected-item-flag',
  templateUrl: './selected-item-flag.component.html',
  styleUrls: ['./selected-item-flag.component.scss'],
  exportAs: 'flag',
})
export class SelectedItemFlagComponent extends SelectionToDisplaySubscriber {

  constructor(
    private ds: InnerDropService,
  ) {
    super(ds);
  }

}
