import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Country } from '@frontend/shared/models';
import { VehicleData } from '../../../+state/exemption.reducer';
import { EnvironmentService } from '@frontend/shared/services';
import { SelectItem } from 'libs/shared/components/src/lib/components/themed-components/select/select.component';
import { germanPlateNumberValidator } from '@frontend/shared/helpers';

@Component({
  selector: 'exemption-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class Epass24Step2Component implements OnInit {
  fuelTypes: string[] = [
    'Gasoline',
    'Gasoline Hybrid',
    'Diesel',
    'Diesel Hybrid',
    'Bio Gasoline',
    'Bio Diesel',
    'Electric',
    'Gas',
    'Hydrogen',
    'Parafin',
    'MZ_OTHER',
  ];

  private euroGroup: string[] = [
    'L1',
    'L2',
    'L3',
    'L4',
    'L5',
    'L6',
    'L7',
    'M1',
    'M2',
    'M3',
    'N1',
    'N2',
    'N3',
    'SA',
    'MZ_OTHER'
  ];

  public emissionClasses: { class: string }[] = [
    {
      class: 'Euro 1',
    },
    {
      class: 'Euro 2',
    },
    {
      class: 'Euro 3',
    },
    {
      class: 'Euro 4',
    },
    {
      class: 'Euro 5',
    },
    {
      class: 'Euro 6',
    },
    {
      class: 'EEV',
    },
    {
      class: 'MZ_OTHER',
    },
  ];

  public vehicleValidator: {
    class: string,
    maxWeight: number,
    minWeight: number,
    maxLength: number,
    minLength: number,
  }[] = [
      {
        class: 'M1',
        maxWeight: 7000,
        minWeight: 500,
        maxLength: 1000,
        minLength: 300,
      },
      {
        class: 'M2',
        maxWeight: 7000,
        minWeight: 1000,
        maxLength: 1000,
        minLength: 300,
      },
      {
        class: 'M3',
        maxWeight: 12000,
        minWeight: 3000,
        maxLength: 1000,
        minLength: 300,
      },
      {
        class: 'N1',
        maxWeight: 7000,
        minWeight: 1000,
        maxLength: 1500,
        minLength: 500,
      },
      {
        class: 'N2',
        maxWeight: 20000,
        minWeight: 3500,
        maxLength: 2000,
        minLength: 500,
      },
      {
        class: 'N3',
        maxWeight: 40000,
        minWeight: 5000,
        maxLength: 4000,
        minLength: 500,
      },
      {
        class: 'MZ_OTHER',
        maxWeight: 100000,
        minWeight: 0,
        maxLength: 50000,
        minLength: 0,
      },
    ];

  isVehicleLenValid: boolean = true;
  isVehicleWgtValid: boolean = true;
  vehicleValidIdx: number = 0;

  private _formData: VehicleData;
  get formData(): VehicleData {
    return this._formData;
  }
  @Input() set formData(newFormData: VehicleData) {
    this._formData = newFormData;
    this.form.patchValue(newFormData, { emitEvent: false });
    if (this.countries && newFormData.country) {
      this.countryControl.patchValue(
        this.findCountryByCountryId(newFormData.country.countryId),
        {
          emitEvent: false,
        }
      );
    }
  }

  @Input() initialCountry: Country;

  private _countries: Country[];
  get countries(): Country[] {
    return this._countries;
  }
  @Input() set countries(newCountries: Country[]) {
    this._countries = newCountries;
    if (this.formData.country) {
      this.countryControl.patchValue(
        this.findCountryByCountryId(this.formData.country.countryId),
        {
          emitEvent: false,
        }
      );
    }
    this.countriesOptions = newCountries.map((country) => ({
      label: country.countryName,
      value: country,
    }));
  }

  @Output() formDataChanged = new EventEmitter<VehicleData>();
  @Output() proceed = new EventEmitter();

  form = new FormGroup(
    {
      registrationNumber: new FormControl('', Validators.required),
      make: new FormControl('', Validators.required),
      model: new FormControl('', Validators.required),
      length: new FormControl('', Validators.required),
      weight: new FormControl('', Validators.required),
      hasParticalFilter: new FormControl(false),
      emissionClass: new FormControl('', Validators.required),
      euroGroup: new FormControl('', Validators.required),
      fuelType: new FormControl('', Validators.required),
      registrationDateDay: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.max(31),
      ]),
      registrationDateMonth: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.max(12),
      ]),
      registrationDateYear: new FormControl(null, [
        Validators.required,
        Validators.min(1000),
      ]),
      country: new FormControl(null, [Validators.required]),
    },
    { validators: germanPlateNumberValidator('registrationNumber', 'country') }
  );

  isUkResidentControl: FormControl = new FormControl(true);

  get countryControl(): AbstractControl {
    return this.form.get('country');
  }

  constructor(private environmentService: EnvironmentService) {
    this.ukResidentOptionEnabled =
      this.environmentService.environment.exemptionUkResidentEnabled ?? true;
  }
  dayMin = 1;
  dayMax = 31;
  monthMin = 1;
  monthMax = 12;
  yearMin = 1000;
  ukResidentOptionEnabled: boolean;
  countriesOptions: SelectItem[];

  emissionClassOptions: SelectItem[] = this.emissionClasses.map(
    (emissionClass) => ({
      label: emissionClass.class,
      value: emissionClass.class,
    })
  );
  
  fuelTypeOptions: SelectItem[] = this.fuelTypes.map((fuelType) => ({
    label: fuelType,
    value: fuelType,
  }));

  euroGroupOptions: SelectItem[] = this.euroGroup.map((group) => ({
    label: group,
    value: group,
  }));

  ngOnInit(): void {
    if (this.ukResidentOptionEnabled) {
      this.isUkResidentControl.valueChanges.subscribe((isUkResident) => {
        if (isUkResident) {
          this.countryControl.setValue(
            this.countries.find((country) => country.countryCode === 'GB')
          );
        }
      });
    }

    this.form.valueChanges.subscribe(() => {
      this.formDataChanged.emit(this.form.getRawValue());
    });

    if (!this.countryControl.value && this.initialCountry) {
      this.countryControl.setValue(this.initialCountry);
    }
  }

  proceedClick(): void {
    if (this.vehicleValidation())
      this.proceed.emit();
  }

  private vehicleValidation(): boolean {
    this.isVehicleLenValid = true;
    this.isVehicleWgtValid = true;
    this.vehicleValidIdx = this.vehicleValidator.findIndex(validator => validator.class === this.form.value.euroGroup)
    console.log(this.vehicleValidIdx);
    
    // if (this.form.value.length > this.vehicleValidator[this.vehicleValidIdx].maxLength)
    //   this.isVehicleLenValid = false;
    // if (this.form.value.weight > this.vehicleValidator[this.vehicleValidIdx].maxWeight)
    //   this.isVehicleWgtValid = false;
    return this.isVehicleLenValid && this.isVehicleWgtValid;
  }

  private findCountryByCountryId(countryId: number) {
    // todo move to helper method
    return this.countries.find((country) => country.countryId === countryId);
  }
}
