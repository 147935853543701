import { Component } from '@angular/core';
import { Country } from '@frontend/shared/models';
import { InnerDropService } from '@frontend/shared/services';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as ExemptionActions from '../+state/exemption.actions';
import {
  ExemptionPartialState,
  PersonalData,
} from '../+state/exemption.reducer';
import * as ExemptionSelectors from '../+state/exemption.selectors';

@Component({
  selector: 'exemption-step1-container',
  templateUrl: './step1.container.html',
})
export class Step1Container {
  personalData$: Observable<PersonalData>;
  countries$: Observable<Country[]>;

  constructor(
    private store: Store<ExemptionPartialState>,
    private innerDropService: InnerDropService
  ) {
    this.personalData$ = this.store.pipe(
      select(ExemptionSelectors.getPersonalData)
    );
    this.countries$ = this.innerDropService.getCountries();
  }

  goToNextStep() {
    this.store.dispatch(ExemptionActions.step1Completed());
  }

  updatePersonalData(personalData: PersonalData) {
    this.store.dispatch(ExemptionActions.personalDataChanged({ personalData }));
  }
}
