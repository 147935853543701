import { Component, OnInit } from '@angular/core';
import { AppReadyService, AppReadyState, LanguagesService } from '@frontend/shared/services';
import { Router, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'EPASS24';

  /**
   *
   */
  constructor(
    private languageService: LanguagesService,
    private appReadyService: AppReadyService,
    private router: Router
  ) {
    const translationReady$ = this.appReadyService.readyStates$.pipe(
      filter((appReadyStates) => {
        return appReadyStates?.includes(AppReadyState.TRANSLATION);
      })
    );
  }

  setLanguage() {
    this.router.events.subscribe((val) => {
      if (val instanceof RoutesRecognized) {
        const paramLang = val.state.root.firstChild.params.lang;
        const current = this.languageService.getCurrentLang();
        const isCurrentLang = current && paramLang === current.id;
        if (!isCurrentLang) {
          this.languageService.setCurrentLang(paramLang);
        }
      }
    });
  }

  ngOnInit() {
    this.setLanguage();
  }
}
