import { createAction, props } from '@ngrx/store';

import { VehicleFormData } from '../model/vehicle-form-data.model';
import { PersonalFormData } from '../model/personal-form-data.model';
import { CreditCardFormModel } from 'libs/shared/components/src/lib/components/credit-card-form/credit-card-form.component';

export const step3Completed = createAction('[Registration] Step 3 completed');
export const step2Completed = createAction('[Registration] Step 2 completed');
export const step1Completed = createAction('[Registration] Step 1 completed');

export const setCurrentStep = createAction(
  '[Registration] Set current step',
  props<{ currentStep: number }>()
);

export const vehicleFormDataChanged = createAction(
  '[Registration] Vehicle form data changed',
  props<{ vehicleFormData: VehicleFormData }>()
);

export const personalFormDataChanged = createAction(
  '[Registration] Personal form data changed',
  props<{ personalFormData: PersonalFormData }>()
);

export const creditCardDataChanged = createAction(
  '[Registration] Credit card data changed',
  props<{ creditCardData: CreditCardFormModel }>()
);
