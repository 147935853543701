import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { catchError } from "rxjs/operators";
import { ApiService } from "../services/api.service";
import { requests } from "../models/requests";
import { ServerTypes } from "../models/servers";
import { ErrorTexts } from "../models/error-texts.enum";
import { Router } from "@angular/router";
import { ClaimsModelWrapper, InvoiceDetailsModel } from "@frontend/shared/interfaces";
import { LanguagesService } from "@frontend/shared/services";

@Injectable()
export class InvoiceService {
  private httpHeaders: HttpHeaders;
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private router: Router,
    private langService: LanguagesService
  ) {
    this.httpHeaders = this.apiService.getHeaders(ServerTypes.claims, 0);
  }

  private get url(): string {
    return this.apiService.getCurentServer(ServerTypes.claims);
  }


  public getAdyenSession(body: any): Observable<any> {
    return this.http.post(`${this.url}/adyen/v1/session/`, body, {
      headers: this.httpHeaders,
    });
  }

  public getInvoiceDetails(referenceNumber: string): Observable<InvoiceDetailsModel> {
   
    const url = `${this.url}/v1/claims/${referenceNumber}/details`;
    return this.http.get<InvoiceDetailsModel>(url);
  }

  public getClaims(currency?: string): Observable<any> {
    const url = currency
      ? `${this.url}/v1/claims2?currency=${currency}`
      : `${this.url}/v1/claims2`;
    return this.http.get(url, { headers: this.httpHeaders }).pipe(
      catchError((error) => {
        if (error["error"]["message"] === ErrorTexts.noValidCard) {
          this.router.navigate([
            this.langService.getCurrentLang().id,
            "account",
            "vehicles",
          ]);
        }
        return error;
      })
    );
  }

  public getEvidenceImage(referenceNumber: string, filename: string): Observable<string> {
    const url = `${this.url}/v1/claims/${referenceNumber}/images/${filename}`;
    return this.http.get<string>(url);
  }

  public postPayment(body: object): Observable<any> {
    return this.http.post(this.url + requests.postPayment2, body, {
      headers: this.httpHeaders,
    });
  }
}
