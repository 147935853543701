import { Injectable } from '@angular/core';
import { EnvironmentService } from '@frontend/shared/services';

@Injectable({ providedIn: 'root' })
export class ExemptionConfigurationService {
  constructor(private environmentService: EnvironmentService) {}

  get exemptionRouteName() {
    return (
      this.environmentService.environment.exemptionRouteName ?? 'exemption'
    );
  }
}
