import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AppStorageEnum } from "../models/storage.enum";
import { StorageService } from "../services/storage.service";

@Injectable()
export class PaymentService {
  private paymentData: BehaviorSubject<any>;
  private invoicesArray: BehaviorSubject<any> = new BehaviorSubject(null);

  public paymentData$: Observable<any>;
  public invoicesArray$: Observable<any>;

  constructor(private storage: StorageService) {
    this.paymentData = new BehaviorSubject(
      JSON.parse(this.storage.getSessionStorageItem(AppStorageEnum.paymentData))
    );
    this.paymentData$ = this.paymentData.asObservable();
    this.invoicesArray$ = this.invoicesArray.asObservable();
  }

  public updatePaymentData(data) {
    this.storage.setSessionStorageItem(
      AppStorageEnum.paymentData,
      JSON.stringify(data)
    );
    this.paymentData.next(data);
  }

  public updateInvoicesArray(arr) {
    this.invoicesArray.next(arr);
  }
}
