import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'exemption-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss'],
})
export class Epass24Step4Component implements OnInit {
  @Output() print = new EventEmitter();
  @Output() finish = new EventEmitter();
  @Output() addAnotherVehicle = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  printClick() {
    this.print.emit();
  }
  finishClick() {
    this.finish.emit();
  }
  addAnotherClick() {
    this.addAnotherVehicle.emit();
  }
}
