import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Country } from '@frontend/shared/models';
import { PersonalData } from '../+state/exemption.reducer';
import { SelectItem } from 'libs/shared/components/src/lib/components/themed-components/select/select.component';
import { UserBrowserInfoService } from '@frontend/shared/services';

@Component({
  selector: 'exemption-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class Step1Component implements OnInit {
  private _countries: Country[];
  get countries(): Country[] {
    return this._countries;
  }

  private _formData: PersonalData;
  get formData(): PersonalData {
    return this._formData;
  }

  @Input() set formData(newFormData: PersonalData) {
    this._formData = newFormData;
    this.form.patchValue(newFormData, { emitEvent: false });
    if (this.countries && newFormData.country) {
      this.countryControl.patchValue(
        this.findCountryByCountryId(newFormData.country.countryId),
        {
          emitEvent: false,
        }
      );
    }
  }

  @Input() set countries(newCountries: Country[]) {
    this._countries = newCountries;
    this.setPhoneNumbers(newCountries);
    if (this.formData.country) {
      this.countryControl.patchValue(
        this.findCountryByCountryId(this.formData.country.countryId),
        {
          emitEvent: false,
        }
      );
    }
    this.countriesOptions = newCountries.map((country) => ({
      label: country.countryName,
      value: country,
    }));
  }

  @Output() formDataChanged = new EventEmitter<PersonalData>();
  @Output() proceed = new EventEmitter();

  phoneCountryCodes: string[];

  form: UntypedFormGroup = this.formBuilder.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    type: ['individual', [Validators.required]],
    companyName: [''],
    address: ['', [Validators.required]],
    postalCode: ['', [Validators.required]],
    city: ['', [Validators.required]],
    country: [null, [Validators.required]],
    email: ['', [Validators.required]],
    mobileNumberPrefix: [''],
    mobileNumber: [''],
    termsAccepted: [false, [Validators.requiredTrue]],
  });

  get typeControl(): AbstractControl {
    return this.form.get('type');
  }

  get mobileNumberPrefixControl(): AbstractControl {
    return this.form.get('mobileNumberPrefix');
  }

  get countryControl(): AbstractControl {
    return this.form.get('country');
  }

  get companyNameControl(): AbstractControl {
    return this.form.get('companyName');
  }

  private setPhoneNumbers(newCountries: Country[]) {
    const notSortedPhoneNumbers = [];
    newCountries.forEach(function (c) {
      if (notSortedPhoneNumbers.indexOf(c.phoneCountryCode) === -1) {
        notSortedPhoneNumbers.push(c.phoneCountryCode);
      }
    });
    this.phoneCountryCodes = notSortedPhoneNumbers.sort((a, b) =>
      a < b ? -1 : 1
    );
    this.mobileNumberPrefixOptions = this.phoneCountryCodes.map(
      (phoneCountryCode) => ({
        label: phoneCountryCode,
        value: phoneCountryCode,
      })
    );
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userBrowserInfoService: UserBrowserInfoService
  ) {}

  countriesOptions: SelectItem[];
  mobileNumberPrefixOptions: SelectItem[];

  ngOnInit(): void {
    this.typeControl.valueChanges.subscribe(() => {
      if (this.typeControl.value === 'company') {
        this.companyNameControl.setValidators(Validators.required);
      } else {
        this.companyNameControl.clearValidators();
      }
    });

    this.form.valueChanges.subscribe(() => {
      this.formDataChanged.emit(this.form.getRawValue());
    });

    if (!this.countryControl.value) {
      const userCountry = this.userBrowserInfoService.findUserCountry(
        this.countries
      );
      if (userCountry) this.countryControl.setValue(userCountry);
    }
    if (!this.mobileNumberPrefixControl.value && this.countryControl.value) {
      const userPhoneCountryCode = this.phoneCountryCodes.find(
        (code) => code === this.countryControl.value.phoneCountryCode
      );

      if (userPhoneCountryCode)
        this.mobileNumberPrefixControl.setValue(userPhoneCountryCode);
    }

    this.countryControl.valueChanges.subscribe((newCountry: Country) => {
      const countryCode = this.phoneCountryCodes.find(
        (code) => code === newCountry.phoneCountryCode
      );
      this.mobileNumberPrefixControl.setValue(countryCode);
    });
  }

  proceedClick(): void {
    this.proceed.emit();
  }

  private findCountryByCountryId(countryId: number) {
    return this.countries.find((country) => country.countryId === countryId);
  }
}
