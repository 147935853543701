import { Action, createReducer, on, UPDATE } from '@ngrx/store';
import * as RegistrationActions from './registration.actions';
import { VehicleFormData } from '../model/vehicle-form-data.model';
import { PersonalFormData } from '../model/personal-form-data.model';
import { CreditCardFormModel } from 'libs/shared/components/src/lib/components/credit-card-form/credit-card-form.component';

export const REGISTRATION_FEATURE_KEY = 'registration';

export interface State {
  completedSteps: number[];
  currentStep: number;
  vehicleFormData: VehicleFormData;
  personalFormData: PersonalFormData;
  creditCardData: CreditCardFormModel;

  isLoading: boolean;
}

export interface RegistrationPartialState {
  readonly [REGISTRATION_FEATURE_KEY]: State;
}

export const initialState: State = {
  completedSteps: [],
  currentStep: 1,
  isLoading: false,
  vehicleFormData: null,
  personalFormData: null,
  creditCardData: null,
};

const registrationReducer = createReducer(
  initialState,
  on(
    RegistrationActions.vehicleFormDataChanged,
    (state, { vehicleFormData }) => ({
      ...state,
      vehicleFormData: { ...state.vehicleFormData, ...vehicleFormData },
    })
  ),
  on(
    RegistrationActions.personalFormDataChanged,
    (state, { personalFormData }) => ({
      ...state,
      personalFormData: { ...state.personalFormData, ...personalFormData },
    })
  ),
  on(
    RegistrationActions.creditCardDataChanged,
    (state, { creditCardData }) => ({
      ...state,
      creditCardData: { ...state.creditCardData, ...creditCardData },
    })
  ),
  on(RegistrationActions.step3Completed, (state) => ({
    ...state,
    isLoading: true,
  })),
  // on(RegistrationActions.step2Completed, onStepCompleted(2, 3)),
  // on(RegistrationActions.step1Completed, onStepCompleted(1, 2)),
  on(RegistrationActions.step2Completed, (state) => ({ ...state, completedSteps: state.completedSteps.includes(2) ? state.completedSteps : [...state.completedSteps, 2], currentStep: 3,  isLoading: false })),  
  on(RegistrationActions.step1Completed, (state) => ({ ...state, completedSteps: state.completedSteps.includes(1) ? state.completedSteps : [...state.completedSteps, 1], currentStep: 2,  isLoading: false })),



  on(RegistrationActions.setCurrentStep, (state, { currentStep }) => ({
    ...state,
    currentStep,
  }))
);

function onStepCompleted(stepNumber: number, nextStepNumber: number) {
  return (state: { completedSteps: number[] }) => ({
    ...state,
    completedSteps: state.completedSteps.includes(stepNumber)
      ? state.completedSteps
      : [...state.completedSteps, stepNumber],
    currentStep: nextStepNumber,
    isLoading: false,
  });
}

export function reducer(state: State | undefined, action: Action) {
  if (action.type === UPDATE) {
    // when loading the state for the first time always initialize it with initial state, and initialize rest with storage state
    return {
      ...initialState,
      ...state,
    };
  }
  return registrationReducer(state, action);
}
