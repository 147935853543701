<frontend-card-page
  [title]="'EXEMPTION_STEP4_HEADER' | translate"
  [subtitle]="'EXEMPTION_STEP4_SUBHEADER' | translate"
>
  <div content fxFlex fxLayout="column">
    <div
      class="buttons-wrapper"
      fxLayout="column"
      fxLayoutAlign="space-evenly none"
    >
      <frontend-button
        [color]="'secondary'"
        (click)="addAnotherClick()"
        type="button"
      >
        {{ 'ADD_ANOTHER_VEHICLE' | translate }}
      </frontend-button>
    </div>
  </div>
</frontend-card-page>
