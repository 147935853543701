import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ExemptionActions from '../+state/exemption.actions';
import { ExemptionPartialState } from '../+state/exemption.reducer';

@Component({
  selector: 'exemption-step4-container',
  templateUrl: './step4.container.html',
})
export class Step4Container {
  constructor(private store: Store<ExemptionPartialState>) {}

  finish() {
    this.store.dispatch(ExemptionActions.exemptionFinish());
  }

  print() {
    this.store.dispatch(ExemptionActions.exemptionPrint({ exemption: null })); // todo pass exemption data for printing
  }

  addAnotherVehicle() {
    this.store.dispatch(ExemptionActions.addAnotherVehicle());
  }
}
