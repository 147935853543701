<epass-header-footer-layout contentGridColumns="1fr 8fr 1fr" headerHeight="70px">
  <div top-bar class="epass-header" gdArea gdColumns.lt-md="1fr" gdColumns.gt-sm="1fr 8fr 1fr" gdRows="minmax(0, 1fr)">
    <div gdColumn.lt-md="1" gdColumn.gt-sm="2" class="d-flex justify-content-end ml-3" ngClass.lt-sm="justify-content-between">
      <a class="d-flex align-items-center mx-md-3" (click)="goToRoute('account')">
        <img class="mr-2" alt="{{ 'HEADER_ACCOUNT_LABEL' | translate }}" src="assets/img/account.png" style="padding-right: 1px" ngClass.lt-md="sm" />
        <p class="mb-0" ngClass.lt-md="sm">
          {{ 'HEADER_ACCOUNT_LABEL' | translate }}
        </p>
      </a>
      <!--<a class="d-flex align-items-center mx-md-3" (click)="goToRoute('HEADER_PAY_LINK')">
        <img class="mr-2" alt="{{ 'HEADER_PAY_LABEL' | translate }}" src="assets/img/pay.png" ngClass.lt-md="sm" />
        <p class="mb-0" ngClass.lt-md="sm">
          {{ 'HEADER_PAY_LABEL' | translate }}
        </p>
      </a>-->
      <frontend-language-picker-container class="d-flex align-items-center mx-3 lang-picker" [type]="'text-dropdown'">
      </frontend-language-picker-container>
    </div>
  </div>

  <epass-header [menuItems]="menuItems" [logoSrc]="'assets/img/logo.png'">
  </epass-header>

  <div footer class="epass-footer">
    <p>{{ 'FOOTER_TEXT' | translate }}</p>
  </div>
</epass-header-footer-layout>
