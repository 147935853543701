<div class="wrapper" fxFlex fxLayout="column">
  <ng-content select="[top-bar]"></ng-content>
  <div
  
    class="header"
    ngClass.lt-md="header wide"
    gdArea
    gdColumns.lt-md="1fr"
    gdColumns.gt-sm="contentGridColumns"
    gdRows="minmax(0, 1fr)"
  >
    <div gdColumn.lt-md="1" gdColumn.gt-sm="2">
      <ng-content select="epass-header"></ng-content>
    </div>
  </div>
  <main
    gdArea
    gdColumns.lt-md="1fr"
    gdColumns.gt-sm="contentGridColumns"
    gdRows.lt-md="1fr"
  >
    <div class="content" gdColumn.lt-md="1" gdColumn.gt-sm="2" gdRow="1">
      <router-outlet></router-outlet>
    </div>
  </main>

  <!-- <div class="layout-footer">
    <ng-content select="[footer]"></ng-content>
  </div> -->
</div>
