import { Component } from '@angular/core';
import { Country } from '@frontend/shared/models';
import { InnerDropService } from '@frontend/shared/services';
import { select, Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import * as ExemptionActions from '../+state/exemption.actions';
import {
  ExemptionPartialState,
  VehicleData,
  PersonalData,
} from '../+state/exemption.reducer';
import * as ExemptionSelectors from '../+state/exemption.selectors';
import { map } from 'rxjs/operators';

@Component({
  selector: 'exemption-step2-container',
  templateUrl: './step2.container.html',
})
export class Step2Container {
  componentData$: Observable<{
    vehicleData: VehicleData;
    personalData: PersonalData;
    countries: Country[];
  }>;

  constructor(
    private store: Store<ExemptionPartialState>,
    private innerDropService: InnerDropService
  ) {
    const vehicleData$ = this.store.pipe(
      select(ExemptionSelectors.getVehicleData)
    );
    const personalData$ = this.store.pipe(
      select(ExemptionSelectors.getPersonalData)
    );
    const countries$ = this.innerDropService.getCountries();
    this.componentData$ = combineLatest(
      vehicleData$,
      personalData$,
      countries$
    ).pipe(
      map((result) => {
        const [vehicleData, personalData, countries] = result;
        return { vehicleData, personalData, countries };
      })
    );
  }

  updateVehicleData(vehicleData: VehicleData) {
    this.store.dispatch(ExemptionActions.vehicleDataChanged({ vehicleData }));
  }

  goToNextStep() {
    this.store.dispatch(ExemptionActions.step2Completed());
  }
}
