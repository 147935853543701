import { Injectable } from '@angular/core';
import { Country } from '@frontend/shared/models';
import { AccountService, EnvironmentService } from '@frontend/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import * as ExemptionActions from './exemption.actions';
import {
  ExemptionPartialState,
  EXEMPTION_FEATURE_KEY,
} from './exemption.reducer';
import { ExemptVehicleForm } from './exempt-vehicle-form.model';

@Injectable()
export class ExemptionEffects {
  step3Completed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExemptionActions.step3Completed),
      withLatestFrom(this.store),
      mergeMap(([action, store]) => {
        const obj = this.createExemptionRequest(store);

        return this.accountService.postAccount(obj).pipe(
          map(() => {
            return ExemptionActions.submitExemptionSuccess();
          }),
          catchError((error) =>
            of(ExemptionActions.submitExemptionError({ message: error }))
          )
        );
      })
    )
  );

  exemptionPrint$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExemptionActions.exemptionPrint),
        tap((action: { exemption: ExemptVehicleForm }) => {
          var popupWin = window.open(
            '',
            '_blank',
            'top=0,left=0,width=900px,height=930px'
          );
          popupWin.document.open();
          popupWin.document.write(
            '<html><head><title></title></head><body onload="window.print(); setTimeout(()=>{ window.close(); }, 0)">\n          ' +
              JSON.stringify(action.exemption) +
              '\n        </body>\n      </html>'
          );
          popupWin.document.close();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<ExemptionPartialState>,
    private accountService: AccountService,
    private environmentService: EnvironmentService
  ) {}

  private createExemptionRequest(store: ExemptionPartialState) {
    const { personalData, imagesData, vehicleData } = {
      ...store[EXEMPTION_FEATURE_KEY],
    };

    let regNumberWithCountry = this.createRegistrationNumber(
      vehicleData.country,
      vehicleData.registrationNumber
    );

    const obj = {
      npId: regNumberWithCountry,
      username: regNumberWithCountry,
      email: personalData.email,
      firstName: personalData.firstName,
      lastName: personalData.lastName,
      mobile: `${personalData.mobileNumberPrefix}${personalData.mobileNumber}`,
      address: personalData.address,
      postalCode: personalData.postalCode,
      city: personalData.city,
      countryId: personalData.country.countryId,
      images: imagesData.images,
      companyName: personalData.companyName,
      clientId: this.environmentService.environment.exemptionClientId,
      additionalData: {
        make: vehicleData.make,
        model: vehicleData.model,
        vehicleCategory: vehicleData.fuelType,
        emission: vehicleData.emissionClass,
        euroGroup: vehicleData.euroGroup,
        length: vehicleData.length,
        weight: vehicleData.weight,
        hasParticalFilter: vehicleData.hasParticalFilter,
        regdate: `${vehicleData.registrationDateYear}-${vehicleData.registrationDateMonth}-${vehicleData.registrationDateDay}`,
      },
    };
    return obj;
  }

  private createRegistrationNumber(
    country: Country,
    registrationNumber: string
  ) {
    return `${country.countryCode}-${registrationNumber
      .split(' ')
      .join('')}`.toUpperCase();
  }
}
