<frontend-card-page
  [title]="'EXEMPTION_STEP3_HEADER' | translate"
  [subtitle]="'EXEMPTION_STEP3_SUBHEADER' | translate"
>
  <div content>
    <p>
      {{ 'EXEMPTION_STEP3_TEXT' | translate }}
    </p>
    <div class="uploader-wrapper">
      <frontend-file-uploader
        (imagesChanged)="onImagesChanged($event)"
        [preloadedFiles]="imagesControl.value"
        [uploadButtonText]="'EXEMPTION_ATTACH_FILES' | translate"
      ></frontend-file-uploader>
    </div>

    <frontend-button
      [disabled]="!form.valid || isLoading"
      (click)="proceedClick()"
      type="button"
    >
      {{ 'EXEMPTION_NEXT' | translate }}
    </frontend-button>
  </div>
</frontend-card-page>
