// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { AppVersion } from "./version";

const adyenLiveKey =
  '10001|873D8EBF5968961D8810B7969E9C645DD0EA4219F62A2DCB655F43' +
  'FDE9334735B4F6CD33B1ACEA1E59C1FBA037A43FBC9CBC54FEB1EF447561' +
  '4F2A2493A86E6FD06A2F12333E363F64C0C94B98FF4E91E17FA5DDB4263C' +
  '9C79706CD1DBA272B515D6161CFAD43D8D8C0B9E2CA03801A2A780D36840' +
  '7D8DDC03F9A672C24B3DB5C7E91991B841A48D4622AE1E98E76A50349B8D' +
  '281E0A7D52866EFFE4713B56050EB54E31346C9D2EAE46BBC59E7B7C8F95' +
  '24B00F63DA3F3EBB0F3ECE1AE96ED3F1C6F951E33FF14E1AF986791377D3' +
  'F634D6B1A0BF79CFC17171030C963B56970A39ADB9F035409B5AB34E8D6C' +
  '6FBD0B4D58A27F6D74D670532EE0CE94B8B16F';

const adyenTestKey =
  '10001|BD4FB09CB817A3422E54E31BF71AF22384B1E55A02DD1827F184F78A3705670A277B7F116AA0AB26CE3148F30004D68E75B95F8BDEFA29E7D62423E33831C9D04EB5E3BDC72DCB21A507A37FE234F595C197180713D76B447352D0505113C5549630838634351EBB53C4B34D3E225BBA6290D3D7AB795DD3C8BBD5A487E44C9BD835671FFD1C67460CA084A6CED3EBE3DC590AA9FAF4A1E140493E6D71E87172B26F6D92B035ABA43C28163EBF4F32806273A96C314329CA8E06624A3028D0EC395665CB72B3324EB1E6637FCE491749AFA5157EDAE06E27251E4529024EE8E944AAB5DC4A18B706CDABFD30A9DA88A1BC84DEC3C6FDEA6AE5D450B5E093EBD5';

export const environment = {
  production: false,
  langUrl: 'https://d18oow8b9b9oyq.cloudfront.net/frontend/4500',
  defaultLang : 'en_GB',
  version: 'v1',
  appVersion: AppVersion.version,
  clientId: '45001',
  adyenKey: adyenLiveKey,
  adyenTestKey: adyenTestKey,
  payment: 'adyencse', //  'adyencse' | 'adyenchk'
  testId: null,
  accountClientId: '46021',
  theme: 'mz',
  clientKey: 'epass24',
  exemptionUkResidentEnabled: false,
  exemptionRouteName: 'vehicleregistration',
  exemptionClientId: '45028',
  languageKeys: ['en','da','de','et','it','lv','lt','no','nl','pl','sv','fi','bu','ro','es','pt','fr'],
};
