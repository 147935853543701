import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { HttpTranslateFactory } from '@frontend/shared/helpers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { EnvironmentService,  EnvironmentServiceFactory,  LanguagesService, AppReadyService, AppReadyState } from '@frontend/shared/services';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { storageSync } from '@larscom/ngrx-store-storagesync';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { SharedComponentsModule } from '@frontend/shared/components';

import { REP_FEATURE_KEY } from 'libs/shared/rep/src/lib/+state/rep.reducer';
import { ApplicationInsightsBasicService } from '@frontend/shared/services';
import { ApplicationInsightsService } from '@frontend/shared/interfaces';
import { RequestLoaderComponent } from './components/request-loader/request-loader.component';
import { InnerSharedModule } from './inner-shared/inner-shared.module';
import { LoadService } from './services/load.service';
import { GlobalErrorHandler } from './providers/global-error.provider';
import { AppAuthInterceptor } from './interceptors/app-auth.interceptor';
import { ComponentsModule } from './components/components.module';
import { FailoverInterceptor } from './interceptors/failover.interceptor';
import { AppRoutingModule } from './app.routing';

import { AuthService as AuthServiceInner,  InvoiceService as InvoiceServiceInner, LayoutService as LayoutServiceInner, RouterService as RouterServiceInner, PaymentService as PaymentServiceInner, ApiService as ApiServiceInner } from './services';
import {  EXEMPTION_FEATURE_KEY,  IMAGES_DATA_STATE_KEY } from '@frontend/shared/exemption';
import { REGISTRATION_FEATURE_KEY } from 'libs/shared/registration/src/lib/+state/registration.reducer';
import { Epass24LayoutComponent } from './components/epass24-layout/epass24-layout.component';

export function storageSyncReducer(reducer: ActionReducer<any>) {
  const metaReducer = storageSync<any>({
    features: [
      {
        stateKey: EXEMPTION_FEATURE_KEY,
        storageForFeature: window.sessionStorage,
        excludeKeys: [IMAGES_DATA_STATE_KEY],
      },
      {
        stateKey: REP_FEATURE_KEY,
        storageForFeature: window.sessionStorage,
        excludeKeys: [IMAGES_DATA_STATE_KEY],
      },
      {
        stateKey: REGISTRATION_FEATURE_KEY,
        storageForFeature: window.sessionStorage,
        excludeKeys: [],
      },
    ],
    storage: window.localStorage,
  });

  return metaReducer(reducer);
}

const SERVICES = [
  AuthServiceInner,
  InvoiceServiceInner,
  LayoutServiceInner,
  RouterServiceInner,
  PaymentServiceInner,
  ApiServiceInner
];

@NgModule({
  declarations: [AppComponent, Epass24LayoutComponent, RequestLoaderComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ComponentsModule,
    InnerSharedModule,
    BrowserAnimationsModule,
    SharedComponentsModule,
    HttpClientModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: [storageSyncReducer],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : [],
    StoreRouterConnectingModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpTranslateFactory,
        deps: [HttpClient, EnvironmentService, AppReadyService],
      },
    }),

    // for HttpClient use:
    LoadingBarHttpClientModule,

    // for Core use:
    LoadingBarModule,
  ],
  providers: [
    {
      provide: EnvironmentService,
      useFactory: () => EnvironmentServiceFactory(environment),
    },
    {
      provide: AppReadyService,
      useFactory: () => {
        const service = new AppReadyService();
        service.setRequiredStates(AppReadyState.TRANSLATION);
        return service;
      },
    },
    LanguagesService,
    SERVICES,
    {
      provide: ApplicationInsightsService,
      useClass: ApplicationInsightsBasicService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FailoverInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    LoadService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
