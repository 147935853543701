export enum ErrorTexts {
  required = 'ERROR_FIELD_REQUIRED',
  email = 'ERROR_EMAIL_INVALID',
  passMinLength = 'ERROR_PASSWORD_LENGTH',
  mismatch = 'ERROR_PASSWORD_MATCH',
  terms = 'ERROR_ACCEPT_TOS',
  postalCode = 'ERROR_POSTALCODE_INVALID',
  emailExists = 'UAM.Exceptions.UserAlreadyExistException',
  numberPlateExists = 'UAM.Exceptions.VehicleAlreadyExistsOther',
  dePlateNumber = 'GERMAN_DELIMITER',
  noValidCard = 'NoValidExternalAccountException',
}
