import {
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { LoadService } from "../services/load.service";
import { Subscription } from "rxjs";

@Directive({
  selector: "[ppRequestDisabled]",
})
export class RequestDisabledDirective implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor(
    private loadService: LoadService,
    private element: ElementRef,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.loadService.isLoading.subscribe((val) => {
        if (this.element.nativeElement.classList.contains("request-loader")) {
          const elClass = this.element.nativeElement.classList;
          !val ? elClass.add("loader-hidden") : elClass.remove("loader-hidden");
        }
        if (this.element.nativeElement.classList.contains("btn")) {
          val
            ? this.renderer.setAttribute(
                this.element.nativeElement,
                "disabled",
                "disabled"
              )
            : setTimeout(() => {
                this.renderer.removeAttribute(
                  this.element.nativeElement,
                  "disabled"
                );
              }, 300);
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
