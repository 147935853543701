<footer class="bg-info text-white py-2 text-center" id="footer">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-auto footer-text">
        <p class="mb-0">
          All rights reserved
          <span class="d-inline-block pr-md-4 copy">&copy; Epass24</span>
        </p>
      </div>
      <div class="col-12 col-md-auto footer-text">
        <nav>
          <a
            [href]="'ACCESSIBILITY_STATEMENT_LINK' | translate"
            class="text-white"
            >{{ 'ACCESSIBILITY_STATEMENT' | translate }}</a
          >
        </nav>
      </div>
    </div>
  </div>
</footer>
