import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { LanguagesService } from '@frontend/shared/services';
import { translateToLink } from '@frontend/shared/helpers';
import { StorageService } from '../../services/storage.service';
import { CoreStorageEnum } from '../../models/core-storage.enum';

import { AuthService } from '../../services/auth.service';
import { UnsubscriberComponent } from '../unsubscriber.component';
import { SidebarLink, sidebarLinks } from '../custom-sidebar/sidebar-link';

@Component({
  selector: 'epass-header',
  templateUrl: './epass-header.component.html',
  styleUrls: ['./epass-header.component.scss']
})
export class EpassHeaderComponent extends UnsubscriberComponent implements OnInit {
  public isLogged = false;
  public isAccount: boolean;
  public accountMenuLinks: SidebarLink[];

  @Input() menuItems: Array<{ text: string; href: string }>;
  @Input() logoSrc: string;

  constructor(
    private auth: AuthService,
    private router: Router,
    protected storage: StorageService,
    private translateService: TranslateService,
    private languagesService: LanguagesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isAccount = this.router.url.includes('/account/');
    this.subscriptions.add(
      this.router.events
        .pipe(filter((e) => e instanceof NavigationEnd))
        .subscribe(
          (e: NavigationEnd) =>
            (this.isAccount = e.urlAfterRedirects.includes('/account/'))
        )
    );
    this.subscriptions.add(
      this.auth.isLogged.subscribe(
        (loginStatus) => (this.isLogged = loginStatus)
      )
    );
      this.accountMenuLinks = sidebarLinks;
  }

  goTo(href: string) {
    const translatedHref = translateToLink(
      href,
      this.languagesService,
      this.translateService
    );
    window.open(translatedHref, '_self');
  }

  goToAccount(href: string) {
    this.router.navigate([
      this.languagesService.getCurrentLang().id,
      'account',
      href,
    ]);
  }

  public logout() {
    this.subscriptions.add(
      this.auth.logout().subscribe(() => {
        this.router.navigate([
          this.languagesService.getCurrentLang().id,
          'account',
          'login',
        ]);
      })
    );
  }

}
