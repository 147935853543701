import { createAction, props } from '@ngrx/store';
import { ExemptVehicleForm } from './exempt-vehicle-form.model';
import { ImagesData, PersonalData, VehicleData } from './exemption.reducer';

export const personalDataChanged = createAction(
  '[Exemption] Personal data changed',
  props<{ personalData: PersonalData }>()
);

export const vehicleDataChanged = createAction(
  '[Exemption] Vehicle data changed',
  props<{ vehicleData: VehicleData }>()
);

export const imagesDataChanged = createAction(
  '[Exemption] Images data changed',
  props<{ imagesData: ImagesData }>()
);

export const step1Completed = createAction('[Exemption] Step 1 completed');

export const step2Completed = createAction('[Exemption] Step 2 completed');

export const step3Completed = createAction('[Exemption] Step 3 completed');

export const submitExemptionSuccess = createAction(
  '[Exemption] Submit exemption success'
);

export const submitExemptionError = createAction(
  '[Exemption] Submit exemption error',
  props<{ message: string }>()
);

export const exemptionFinish = createAction('[Exemption] Exemption finish');

export const exemptionPrint = createAction(
  '[Exemption] Print exemption',
  props<{ exemption: ExemptVehicleForm }>()
);

export const setCurrentStep = createAction(
  '[Exemption] Set current step',
  props<{ currentStep: number }>()
);

export const addAnotherVehicle = createAction(
  '[Exemption] Add another vehicle'
);
