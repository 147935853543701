import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LayoutService } from '../../services';
import { filter, takeWhile } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'pp-layout',
  styleUrls: ['./layout.component.scss'],
  template: `
    <div class="flex-grow-1">
      <pp-header
        (toggleScrolling)="toggleScrolling($event)"
        *ngIf="!receipt"
      ></pp-header>
      <ng-content></ng-content>
    </div>
    <pp-footer *ngIf="!receipt"></pp-footer>
  `,
})
export class LayoutComponent implements OnInit, OnDestroy {
  private alive = true;

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    this.layoutService.changeWindowWidth(event.target.innerWidth);
  }

  @HostListener('window:scroll', ['$event'])
  public scrollHandler(event) {
    const scrollingElement =
      event.target.scrollingElement || this.document.documentElement;
    this.scrolled = !!scrollingElement.scrollTop;
  }

  @HostBinding('class.is-mobile') public isMobile = false;
  @HostBinding('class.home') public home = false;
  @HostBinding('class.no-scroll') public noScroll = false;
  @HostBinding('class.scrolled') public scrolled = false;
  @HostBinding('class.print-receipt') public receipt = false;

  public constructor(
    private layoutService: LayoutService,
    private router: Router,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public ngOnInit() {
    this.layoutService.changeWindowWidth(window.innerWidth);
    this.layoutService.onMediaQueryChange().subscribe(([prev, current]) => {
      this.isMobile = prev.width > current.width;
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeWhile(() => this.alive)
      )
      .subscribe((event: NavigationEnd) => {
        if (event.urlAfterRedirects.includes('account') || this.isMobile) {
          this.elementRef.nativeElement.scrollTop = 0;
        }
        this.home = event.urlAfterRedirects.includes('account/step1');
        this.receipt = event.urlAfterRedirects.includes('preview');
      });
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public toggleScrolling(ev) {
    this.noScroll = ev;
  }
}
