export const protectedPages = [
  'pay/step2',
  'pay/step3',
  'pay/step4',
  'account/myaccount',
  'account/passages',
  'account/settings',
  'account/vehicles',
  'account/card',
  'account/invoices',
];
