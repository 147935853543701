import { Component, OnInit, Renderer2 } from "@angular/core";
import { AbstractControl, ControlContainer } from "@angular/forms";
import {
  NgbCalendar,
  NgbDate,
  NgbTimeAdapter,
} from "@ng-bootstrap/ng-bootstrap";
import { closest } from "../closestFunction";
import { NgbTimeStringAdapter } from "./timepicker-adapter";

@Component({
  selector: "pp-range-date-picker",
  templateUrl: "./range-date-picker.component.html",
  styleUrls: ["./range-date-picker.component.scss"],
  providers: [
    {
      provide: NgbTimeAdapter,
      useClass: NgbTimeStringAdapter,
    },
  ],
})
export class RangeDatePickerComponent implements OnInit {
  public formGroup: AbstractControl | null;
  public showTime: boolean = false;
  public fromDate: NgbDate;
  public toDate: NgbDate;
  public hoveredDate: NgbDate;
  private maxPeriod: number = 1500;
  private maxDaysBefore: number = 600;

  constructor(
    private controlContainer: ControlContainer,
    private calendar: NgbCalendar,
    private renderer: Renderer2,
  ) {}


  public ngOnInit() {
    // bind form instance
    this.formGroup = this.controlContainer.control;

    // set default start date
    this.fromDate = this.calendar.getToday();
    this.formGroup.patchValue({
      dateStart: this.convertNgbDate(
        this.fromDate,
        this.formGroup.get("timeStart").value
      ),
    });
  }

  isDateDisabled = (date: NgbDate, current: {month: number, year: number}) => date.before(this.countFirstValidDate(this.calendar.getToday()));

  private countFirstValidDate(startDate: NgbDate) {
    return this.calendar.getNext(startDate, "d", -this.maxDaysBefore);
  }

  public stateChanged(isOpened: boolean): void {
    isOpened
      ? this.renderer.addClass(closest(document.body, "html"), "no-scroll")
      : this.renderer.removeClass(closest(document.body, "html"), "no-scroll");
  }

  public convertToNgbDate(date: Date): NgbDate {
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  public getNgbToday(): NgbDate {
    return this.calendar.getToday();
  }

  public isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  public isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  public isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  public countLastAvailableDate(startDate: NgbDate) {
    return this.calendar.getNext(startDate, "d", this.maxPeriod);
  }

  public convertNgbDate(date: NgbDate, time: string): string {
    if (date) {
      const timeArray = time.split(":");
      return new Date(
        date.year,
        date.month - 1,
        date.day,
        parseInt(timeArray[0], 10),
        parseInt(timeArray[1], 10)
      ).toISOString();
    } else {
      return "";
    }
  }

  public onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.formGroup.patchValue({
        dateStart: this.convertNgbDate(
          date,
          this.formGroup.get("timeStart").value
        ),
      });
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.formGroup.patchValue({
        dateEnd: this.convertNgbDate(
          this.toDate,
          this.formGroup.get("timeEnd").value
        ),
      });
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.formGroup.patchValue({
        dateEnd: this.convertNgbDate(null, this.formGroup.get("timeEnd").value),
        dateStart: this.convertNgbDate(
          date,
          this.formGroup.get("timeStart").value
        ),
      });
    }
  }

  public preventClose(e) {
    if (
      e.target.classList.value.includes("show") ||
      closest(e.target, ".dropdown").classList.value.includes("show")
    ) {
      e.stopPropagation();
    }
  }
}
