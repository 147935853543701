export enum uamRequests {
  password = '/v1/users/password',
  vehicles = '/v1/users/vehicles',
  updateAccount = '/v1/users/settings',
  passwordReset = '/v1/users/settings/password',
  validateEmail = '/v1/validators/username/',
  validateNumberPlate = '/v1/validators/vehicle/',
  deleteUser = '/v1/users',
}

export enum uam2Requests {
  password = '/client/v1/users/password',
  vehicles = '/client/v1/vehicles/',
  updateAccount = '/client/v1/settings/details',
  getCardInfo = '/client/v1/wallet/accounts',
  addCard = '/client/v1/wallet/accounts',
  removeCard = '/client/v1/wallet/accounts',
  removeEntity = '/client/v1/vehicles/entities',
  accountInvoices = '/client/v1/invoices',
  invoiceDetail = '/client/v1/invoices/',
  passwordReset = '/client/v1/settings/password',
  logOut = '/client/v1/settings/token/',
  validateEmail = '/client/v1/validators/username',
  validateNumberPlate = '/client/v1/validators/vehicle',
  userAccount = '/client/v1/users',
  invoicePDF = '/client/v1/documents',
}

export enum requests {
  token = '/v1/auth/token',
  accountData = '/v1/wallet/getsavedaccounts',
  applyPayment = '/v1/wallet/deposit',
  claims = '/v1/claims',
  addCard = '/v1/wallets/accounts/',
  getCardInfo = '/v1/wallets/accounts',
  removeCard = '/v1/wallets/accounts/',
  countries = '/v1/system/countries',
  languages = '/client/v1/system/languages',
  currencies = '/client/v1/system/currencies',
  postPayment = '/v1/wallet/payments',
  postPayment2 = '/v2/wallet/payments',
  createAccount = '/v1/wallet/autopay/ff',
  accountInvoices = '/v1/wallets/transactions/summaries/monthly',
  monthlyTransactions = '/v1/wallets/transactions',
  exemptTransactons = '/v1/externaldata/passages',
  invoicePDF = '/v1/documents/notification/',
  accountPay = '/v1/wallet/autopaydeposit/ff',
  receipt = '/v1/receipt/',
}
