import {DatePipe} from '@angular/common';
import {Directive, ElementRef, forwardRef, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: '[ppDateValAccessor]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateValAccessorDirective),
    multi: true,
  }],
})
export class DateValAccessorDirective implements ControlValueAccessor {

  constructor(
    private datePipe: DatePipe,
    private renderer: Renderer2,
    private elRef: ElementRef,
  ) {}

  public writeValue(value) {
    this.renderer.setAttribute(this.elRef.nativeElement, 'value', value ? this.datePipe.transform(value, 'dd-MM-yyyy') : '');
  }

  public registerOnChange() {}

  public registerOnTouched() {}

}
