import {Component, Input} from '@angular/core';
import {DropItemCountry} from '../../interfaces/drop-item-country';

@Component({
  selector: 'pp-drop-item-country',
  templateUrl: './drop-item-country.component.html',
  styleUrls: ['./drop-item-country.component.scss'],
})
export class DropItemCountryComponent {
  @Input() public country: DropItemCountry;
}
