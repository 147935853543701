import { Injectable, OnInit } from "@angular/core";
import { UnsubscriberComponent } from "../../../components/unsubscriber.component";
import { InnerDropService } from "../inner-drop.service";

@Injectable()
export class SelectionToDisplaySubscriber
  extends UnsubscriberComponent
  implements OnInit {
  public content: string;

  constructor(private dropService: InnerDropService) {
    super();
  }

  public ngOnInit() {
    this.subscriptions.add(
      this.dropService.$selectionToDisplay.subscribe((selection) => {
        this.content = selection;
      })
    );
  }
}
