import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { HttpTranslateFactory } from '@frontend/shared/helpers';
import { EnvironmentService } from '@frontend/shared/services';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CardPageComponent } from './components/card-page/card-page.component';
import { CountriesPickerComponent } from './components/countries-picker/countries-picker.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { HeaderOnlyLayoutComponent } from './components/header-only-layout/header-only-layout.component';
import { HeaderComponent } from './components/header/header.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ButtonComponent } from './components/themed-components/button/button.component';
import { CheckboxInputComponent } from './components/themed-components/checkbox-input/checkbox-input.component';
import { FormGroupComponent } from './components/themed-components/form-group/form-group.component';
import { LabelComponent } from './components/themed-components/label/label.component';
import { PhoneInputComponent } from './components/themed-components/phone-input/phone-input.component';
import { RadioInputComponent } from './components/themed-components/radio-input/radio-input.component';
import { TextInputComponent } from './components/themed-components/text-input/text-input.component';
import { RequestDisabledDirective } from './directives/request-disabled.directive';
import { FormComponent } from './components/themed-components/form/form.component';
import { StepperComponent } from './components/themed-components/stepper/stepper.component';
import { TextAreaInputComponent } from './components/themed-components/text-area-input/text-area-input.component';
import { ThemedLayoutComponent } from './components/themed-components/themed-layout/themed-layout.component';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { LanguagePickerContainer } from './components/language-picker/language-picker.container';
import { HeaderFooterLayoutComponent } from './components/header-footer-layout/header-footer-layout.component';
import { LanguagePickerDropdownComponent } from './components/language-picker/language-picker-dropdown.component';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LanguagePickerTextDropdownComponent } from './components/language-picker/language-picker-text-dropdown.component';
import { SelectComponent } from './components/themed-components/select/select.component';
import { MarkWithErrorDirective } from './components/themed-components/directives/mark-with-error.directive';
import { ErrorTextComponent } from './components/themed-components/error-text/error-text.component';
import { CreditCardFormComponent } from './components/credit-card-form/credit-card-form.component';
import { RangeDatePickerComponent } from './components/range-date-picker/range-date-picker.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DateValAccessorDirective } from './components/range-date-picker/date-val-accessor.directive';
import { FileUploaderMultiComponent } from './components/file-uploader/file-uploader-multi.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [
    RequestDisabledDirective,
    HeaderOnlyLayoutComponent,
    HeaderComponent,  
    CardPageComponent,
    CountriesPickerComponent,
    FileUploaderComponent,
    SpinnerComponent,
    ButtonComponent,
    TextInputComponent,
    LabelComponent,
    FormGroupComponent,
    RadioInputComponent,
    CheckboxInputComponent,
    PhoneInputComponent,
    FormComponent,
    StepperComponent,
    TextAreaInputComponent,
    ThemedLayoutComponent,
    LanguagePickerComponent,
    LanguagePickerContainer,
    HeaderFooterLayoutComponent,
    LanguagePickerDropdownComponent,
    LanguagePickerTextDropdownComponent,
    SelectComponent,
    MarkWithErrorDirective,
    ErrorTextComponent,
    CreditCardFormComponent,
    RangeDatePickerComponent,
    DateValAccessorDirective,
    FileUploaderMultiComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    OverlayModule,
    ReactiveFormsModule,
    FormsModule,
    MatRadioModule,
    MatCheckboxModule,
    MatStepperModule,
    NgbDropdownModule,
    NgbModule,
    TranslateModule.forChild({
      loader: {
        deps: [HttpClient, EnvironmentService],
        provide: TranslateLoader,
        useFactory: HttpTranslateFactory,
      },
    }),
  ],
  providers: [DatePipe],
  exports: [
    RequestDisabledDirective,
    HeaderOnlyLayoutComponent,
    HeaderComponent,
    CardPageComponent,
    CountriesPickerComponent,
    FileUploaderComponent,
    FileUploaderMultiComponent,
    SpinnerComponent,
    ButtonComponent,
    TextInputComponent,
    LabelComponent,
    FormGroupComponent,
    RadioInputComponent,
    CheckboxInputComponent,
    PhoneInputComponent,
    FormComponent,
    StepperComponent,
    TextAreaInputComponent,
    ThemedLayoutComponent,
    LanguagePickerContainer,
    HeaderFooterLayoutComponent,
    LanguagePickerDropdownComponent,
    SelectComponent,
    MarkWithErrorDirective,
    ErrorTextComponent,
    CreditCardFormComponent,
    RangeDatePickerComponent,
  ],
})
export class SharedComponentsModule {}
