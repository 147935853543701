import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule, Routes } from '@angular/router';
import { SharedComponentsModule } from '@frontend/shared/components';
import { HttpTranslateFactory } from '@frontend/shared/helpers';
import { EnvironmentService } from '@frontend/shared/services';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ExemptionEffects } from './+state/exemption.effects';
import * as fromExemption from './+state/exemption.reducer';
import { ExemptionLayoutComponent } from './exemption-layout/exemption-layout.component';
import { Step1Container } from './step1/step1.container';
import { Step2Container } from './step2/step2.container';
import { Step3Container } from './step3/step3.container';
import { Step4Container } from './step4/step4.container';
import { exemptionConfiguration } from './configuration/exemption.configuration';

const routes: Routes = [
  {
    path: '',
    component: ExemptionLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'step1',
        pathMatch: 'full',
      },
      {
        path: 'step1',
        component: Step1Container,
      },
      {
        path: 'step2',
        component: Step2Container,
      },
      {
        path: 'step3',
        component: Step3Container,
      },
      {
        path: 'step4',
        component: Step4Container,
      },
    ],
  },

  {
    path: '**',
    redirectTo: 'step1',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild({
      loader: {
        deps: [HttpClient, EnvironmentService],
        provide: TranslateLoader,
        useFactory: HttpTranslateFactory,
      },
    }),
    StoreModule.forFeature(
      fromExemption.EXEMPTION_FEATURE_KEY,
      fromExemption.reducer
    ),
    EffectsModule.forFeature([ExemptionEffects]),
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatStepperModule,
    MatAutocompleteModule
  ],
  declarations: [
    exemptionConfiguration.step1,
    Step1Container,
    exemptionConfiguration.step2,
    Step2Container,
    exemptionConfiguration.step3,
    Step3Container,
    exemptionConfiguration.step4,
    Step4Container,
    ExemptionLayoutComponent,
  ]
})
export class SharedExemptionModule {}
