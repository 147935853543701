import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'pp-intro-image',
  templateUrl: './intro-image.component.html',
})
export class IntroImageComponent implements OnInit {
  @Input() public image: string;

  constructor() { }

  public ngOnInit() {
  }

}
