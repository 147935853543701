<div class="sidebar pt-0">
  <ul class="sidebar-nav list-unstyled">
    <li *ngFor="let link of links" class="sidebar-item">
      <a (click)="getLink(link.link)" [id]="link.id" [class.active]="isActive(link.link)" class="d-flex align-items-center">
        {{link?.text | translate }}
      </a>
    </li>
  </ul>
  <div class="btn-holder mt-5">
    <button
      id="button-account-logout"
      type="button"
      class="btn btn-success w-100 d-flex align-items-center justify-content-center"
      (click)="logout()"
      [class.btn-lg]="!isMobile"
    >{{ 'LOGOUT' | translate }} <i class="pp-icon-arrow-back inverted ml-2"></i></button>
  </div>
</div>
