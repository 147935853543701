import { OnDestroy, Directive } from "@angular/core";
import { Subscription } from "rxjs";

// TODO: Add Angular decorator.
@Directive()
export class UnsubscriberComponent implements OnDestroy {
  public subscriptions = new Subscription();

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
