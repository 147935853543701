<div
  ngbDropdown
  #dropdown="ngbDropdown"
  [placement]="['bottom-right']"
  (openChange)="openHandler($event)"
  class="{{props.extraClass}}"
  *ngIf="props.selectedItem"
  [autoClose]="'outside'"
>
  <div class="selected-item btn btn-default item" [ngClass]="{'disabled drop-disabled': disabled, 'dropdown-toggle': !disabled }" (click)="!disabled && dropdown.toggle()">
    <ng-container [ngSwitch]="props.selectedItemType">
      <pp-selected-item-flag *ngSwitchCase="selectedItemTypes.flag"></pp-selected-item-flag>
      <pp-selected-item-country-code *ngSwitchCase="selectedItemTypes.countryCode"></pp-selected-item-country-code>
    </ng-container>
  </div>
  <div ngbDropdownMenu aria-labelledby="selectLanguage">
    <div>
      <ng-container [ngSwitch]="props.dropType">
        <div *ngSwitchCase="dropTypes.country" class="row">
          <div class="col-6 item item-wrap" *ngFor="let item of props.dropItems" (click)="selectItem(item); dropdown.close()">
            <pp-drop-item-country [country]="item"></pp-drop-item-country>
          </div>
        </div>
      </ng-container>
      <ng-content></ng-content>
    </div>
  </div>
</div>
