import { EventEmitter, Input, OnInit, Output, Directive } from "@angular/core";
import { UnsubscriberComponent } from "./unsubscriber.component";
import { DropdownService } from "../services/dropdown.service";
import { DropProps } from "./drop-props";

@Directive()
export abstract class DropdownBaseComponent
  extends UnsubscriberComponent
  implements OnInit {
  @Input() public props: DropProps;
  @Output() public selection: EventEmitter<any> = new EventEmitter();
  @Output() public state: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private dropService: DropdownService,
    public selectedItemTypes,
    public dropTypes
  ) {
    super();
  }

  public ngOnInit() {
    this.dropService.updateSelectionToDisplay(this.props.selectedItem);
    this.dropService.updateSelectedItemType(this.props.selectedItemType);
    this.dropService.updateItemType(this.props.dropType);
    this.subscriptions.add(
      this.dropService.$selectedItemType.subscribe(
        (itemType) => (this.props.selectedItemType = itemType)
      )
    );
  }

  public stateChanged(isOpened: boolean): void {
    this.state.emit(isOpened);
  }

  public selectItem(item: any): void {
    this.dropService.updateSelectionToDisplay(item.code);
    this.selection.emit(item);
  }
}
