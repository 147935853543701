<frontend-card-page [title]="'EXEMPTION_STEP1_HEADER' | translate">
  <div content fxLayout="column" class="content">
    <p fxFlex="0 1 auto">
      {{ 'EXEMPTION_STEP1_TEXT' | translate }}
    </p>
    <div class="form-wrapper">
      <frontend-form [formGroup]="form" class="step1-form">
        <div style="padding-bottom: 1rem">
          <frontend-radio-input
            formControlName="type"
            [radios]="[
              {
                id: 'individual',
                label: 'INDIVIDUAL' | translate,
                value: 'individual'
              },
              {
                id: 'company',
                label: 'COMPANY' | translate,
                value: 'company'
              }
            ]"
          >
          </frontend-radio-input>
        </div>
        <div class="step1-form-row">
          <frontend-form-group fxFlex="49">
            <frontend-label for="first-name">{{
              'FIRST_NAME' | translate
            }}</frontend-label>
            <frontend-text-input
              type="text"
              id="first-name"
              formControlName="firstName"
            ></frontend-text-input>
          </frontend-form-group>
          <frontend-form-group fxFlex="49">
            <frontend-label for="last-name">{{
              'LAST_NAME' | translate
            }}</frontend-label>
            <frontend-text-input
              type="text"
              id="last-name"
              formControlName="lastName"
            ></frontend-text-input>
          </frontend-form-group>
        </div>
        <div class="step1-form-row" *ngIf="typeControl.value === 'company'">
          <frontend-form-group fxFlex="100">
            <frontend-label for="company-name">{{
              'COMPANY_NAME' | translate
            }}</frontend-label>
            <frontend-text-input
              type="text"
              id="company-name"
              formControlName="companyName"
            ></frontend-text-input>
          </frontend-form-group>
        </div>
        <div class="step1-form-row">
          <frontend-form-group fxFlex="100">
            <frontend-label for="address">{{
              'ADDRESS' | translate
            }}</frontend-label>
            <frontend-text-input
              type="text"
              id="address"
              formControlName="address"
            ></frontend-text-input>
          </frontend-form-group>
        </div>

        <div
          class="step1-form-row"
          *ngIf="countryControl.value?.countryCode !== 'GB'"
        >
          <frontend-form-group fxFlex="39">
            <frontend-label for="postal-code">{{
              'POSTAL_CODE' | translate
            }}</frontend-label>
            <frontend-text-input
              type="text"
              id="postal-code"
              formControlName="postalCode"
            ></frontend-text-input>
          </frontend-form-group>
          <frontend-form-group fxFlex="59">
            <frontend-label for="city">{{ 'CITY' | translate }}</frontend-label>
            <frontend-text-input
              type="text"
              id="city"
              formControlName="city"
            ></frontend-text-input>
          </frontend-form-group>
        </div>

        <div
          class="step1-form-row"
          *ngIf="countryControl.value?.countryCode === 'GB'"
        >
          <frontend-form-group fxFlex="59">
            <frontend-label for="city">{{ 'CITY' | translate }}</frontend-label>
            <frontend-text-input
              type="text"
              id="city"
              formControlName="city"
            ></frontend-text-input>
          </frontend-form-group>
          <frontend-form-group fxFlex="39">
            <frontend-label for="postal-code">{{
              'POSTAL_CODE' | translate
            }}</frontend-label>
            <frontend-text-input
              type="text"
              id="postal-code"
              formControlName="postalCode"
            ></frontend-text-input>
          </frontend-form-group>
        </div>

        <div class="step1-form-row">
          <frontend-form-group fxFlex="100">
            <frontend-label for="country">{{
              'COUNTRY' | translate
            }}</frontend-label>
            <frontend-select
              id="country"
              formControlName="country"
              [options]="countriesOptions"
            >
            </frontend-select>
          </frontend-form-group>
        </div>
        <div class="step1-form-row">
          <frontend-form-group fxFlex="100">
            <frontend-label for="email">{{
              'EMAIL' | translate
            }}</frontend-label>
            <frontend-text-input
              type="email"
              id="email"
              formControlName="email"
            ></frontend-text-input>
          </frontend-form-group>
        </div>
        <div class="step1-form-row">
          <frontend-form-group fxFlex="39">
            <frontend-label for="prefix">{{
              'PREFIX' | translate
            }}</frontend-label>
            <frontend-select
              id="prefix"
              formControlName="mobileNumberPrefix"
              [options]="mobileNumberPrefixOptions"
            >
            </frontend-select>
          </frontend-form-group>
          <frontend-form-group fxFlex="59">
            <frontend-label for="mobile-number">{{
              'MOBILE_NUMBER' | translate
            }}</frontend-label>
            <frontend-phone-input
              id="mobile-number"
              formControlName="mobileNumber"
            ></frontend-phone-input>
          </frontend-form-group>
        </div>
        <div class="step1-form-row">
          <div class="terms-wrapper">
            <frontend-checkbox-input id="terms" formControlName="termsAccepted">
              <a
                href="{{ 'TERMS_CONDITIONS_LINK' | translate }}"
                target="_blank"
                >{{ 'TERMS_CONDITIONS_LABEL' | translate }}</a
              >
            </frontend-checkbox-input>
          </div>
        </div>

        <frontend-button
          [disabled]="!form.valid"
          (click)="proceedClick()"
          type="button"
        >
          {{ 'EXEMPTION_NEXT' | translate }}
        </frontend-button>
      </frontend-form>
    </div>
  </div>
</frontend-card-page>
