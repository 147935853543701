import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { Epass24LayoutComponent } from './components/epass24-layout/epass24-layout.component';

const routerConfig: ExtraOptions = {
    useHash: false
};

const scrollRestorationDescriptor = Object.getOwnPropertyDescriptor(
  Object.getPrototypeOf(history),
  'scrollRestoration'
);

const supportManualScrollRestoration: boolean =
  !!window &&
  'scrollTo' in window &&
  'scrollX' in window &&
  'scrollY' in window &&
  !!history &&
  'scrollRestoration' in history &&
  !!scrollRestorationDescriptor &&
  (scrollRestorationDescriptor.writable || !!scrollRestorationDescriptor.set);

if (supportManualScrollRestoration) {
  routerConfig.scrollPositionRestoration = 'enabled';
}

const routes: Routes = [
  {
    path: ':lang',

    component: Epass24LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'pay',
        pathMatch: 'full',
      },
      {
        path: 'rep',
        loadChildren: () =>
          import('@frontend/shared/rep').then((m) => m.SharedRepModule),
      },
      // {
      //   path: 'pay',
      //   loadChildren: () =>
      //     import('@frontend/shared/payment').then((m) => m.SharedPaymentModule),
      // },
      // {
      //   path: 'vehicleregistration',
      //   loadChildren: () =>
      //     import('@frontend/shared/exemption').then(
      //       (m) => m.SharedExemptionModule
      //     ),
      // },
      {
        path: 'vehicleregistration',
        loadChildren: () =>
          import('@frontend/epass24/app/pages/flows/environment-registration/environment-registration.module').then(
            (m) => m.EnvironmentRegistrationModule
          ),
      },
      {
        path: 'test',
        loadChildren: () =>
          import('@frontend/shared/test').then((m) => m.SharedTestModule),
      },
      // {
      //   path: 'account',
      //   loadChildren: () =>
      //     import('./pages/account/account.module').then((m) => m.AccountModule),
      // },
      {
        path: 'passwordrecovery',
        loadChildren: () =>
          import('./pages/reset-password/reset-password.module').then(
            (m) => m.ResetPasswordModule
          ),
      },
      {
        path: 'passwordrecovery',
        loadChildren: () =>
          import('./pages/reset-password-mobile/reset-password-mobile.module').then(
            (m) => m.ResetPasswordModule
          ),
      },
      {
        path: 'create',
        loadChildren: () =>
          import('./pages/flows/create-account/create-account.module').then(
            (m) => m.CreateAccountModule
          ),
      },
      {
        path: 'registration',
        loadChildren: () =>
          import('./pages/flows/registration/src/lib/shared-registration.module').then(
            (m) => m.SharedRegistrationModule
          ),
      },
      {
        path: 'receipt',
        loadChildren: () =>
          import('./pages/flows/receipt/receipt.module').then(
            (m) => m.ReceiptModule
          ),
      },
      {
        path: '**',
        redirectTo: 'pay',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'en/pay',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
