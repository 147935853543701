import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { OnlyNumberDirective } from '../directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { InnerDropdownBaseComponent } from './dropdown/dropdown-base/dropdown-base.component';
import { DropItemCountryComponent } from './dropdown/dropdownItemTypes/drop-item-country/drop-item-country.component';
import { AdditionalDropItemsComponent } from './dropdown/extentions/additional-drop-items/additional-drop-items.component';
import { AdditionalDropItemsEpass24Component } from './dropdown/extentions/additional-drop-items-epass24/additional-drop-items-epass24.component';
import { InnerDropService } from './dropdown/inner-drop.service';
import { SelectedItemCountryCodeComponent } from './dropdown/selectedItemTypes/selected-item-country-code/selected-item-country-code.component';
import { SelectedItemFlagComponent } from './dropdown/selectedItemTypes/selected-item-flag/selected-item-flag.component';
import { SelectionToDisplaySubscriber } from './dropdown/selectedItemTypes/selection-to-display-subscriber';
import { DateValAccessorDirective } from './range-date-picker/date-val-accessor.directive';
import { RangeDatePickerComponent } from './range-date-picker/range-date-picker.component';
import { RequestDisabledDirective } from '../directives/request-disabled.directive';
import { EnvironmentService } from '@frontend/shared/services';
import { HttpTranslateFactory } from '@frontend/shared/helpers';

@NgModule({
  declarations: [
    InnerDropdownBaseComponent,
    SelectedItemFlagComponent,
    DropItemCountryComponent,
    SelectedItemCountryCodeComponent,
    AdditionalDropItemsComponent,
    AdditionalDropItemsEpass24Component,
    RangeDatePickerComponent,
    DateValAccessorDirective,
    OnlyNumberDirective,
    RequestDisabledDirective,
  ],
  imports: [
    NgbModule,
    CommonModule,
    TranslateModule.forChild({
      loader: {
        deps: [HttpClient, EnvironmentService],
        provide: TranslateLoader,
        useFactory: HttpTranslateFactory,
      },
    }),
    ReactiveFormsModule,
  ],
  providers: [InnerDropService, SelectionToDisplaySubscriber, DatePipe],
  exports: [
    InnerDropdownBaseComponent,
    SelectedItemFlagComponent,
    DropItemCountryComponent,
    SelectedItemCountryCodeComponent,
    AdditionalDropItemsComponent,
    AdditionalDropItemsEpass24Component,
    RangeDatePickerComponent,
    OnlyNumberDirective,
    RequestDisabledDirective,
  ],
})
export class InnerSharedModule {}
