<pp-dropdown-base
  [props]="mainDropProps"
  [disabled]="disabled"
  (state)="stateHandler()"
  (selection)="handleSelection($event.code)"
  (countriesLoaded)="handleSecondaryCountries($event)"
  #dropdown="dropdown"
  [ngStyle]="{
    'position': 'static',
    'margin': '0'
  }">
  <div class="container">
    <div class="row justify-content-between pt-2 pb-2">
      <div class="col-auto">
        <button
          type="button"
          #overlay
          (click)="toggleSecondaryList($event)"
          class="btn btn-link">show more countries</button>
      </div>
      <div class="col-auto d-md-none">
        <button
          type="button"
          class="btn btn-link" (click)="dropWrap.dropdown.close()">close</button>
      </div>
    </div>
  </div>
  <div class="select-overlay" *ngIf="showSecondaryList">
    <i class="fa fa-times btn-close" (click)="toggleSecondaryList($event)"></i>
    <ul class="list-unstyled countries-holder">
      <li *ngFor="let item of mainDropProps.additionalDropItems" (click)="handleSelection(item.code)">
        {{item.name}}
      </li>
    </ul>
  </div>
</pp-dropdown-base>
