import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { DropdownService } from "../../services/dropdown.service";
import { ApiService } from "../../services/api.service";
import { SortingFunctionsService } from "./sorting-functions.service";
import { DropdownSelectedItemTypes } from "./dropdown-selected-item-types.enum";
import { countriesEU } from "../../models/countriesEU";
import { AppStorageEnum } from "../../models/storage.enum";
import { requests } from "../../models/requests";
import { ServerTypes } from "../../models/servers";
import { StorageService } from "../../services/storage.service";

@Injectable()
export class InnerDropService extends DropdownService {
  private items: BehaviorSubject<
    DropdownSelectedItemTypes[]
  > = new BehaviorSubject([]);
  public items$: Observable<DropdownSelectedItemTypes[]>;
  private extraItems: BehaviorSubject<
    DropdownSelectedItemTypes[]
  > = new BehaviorSubject([]);
  public extraItems$: Observable<DropdownSelectedItemTypes[]>;

  constructor(
    private client: HttpClient,
    private sf: SortingFunctionsService,
    private as: ApiService,
    private storage: StorageService
  ) {
    super(client, as);
    this.items$ = this.items.asObservable();
    this.extraItems$ = this.extraItems.asObservable();
  }

  public updateItems(arr: DropdownSelectedItemTypes[]): void {
    this.items.next(arr);
  }
  public updateExtraItems(arr: DropdownSelectedItemTypes[]): void {
    this.extraItems.next(arr);
  }

  public getEUCountries(array): any[] {
    return array
      .filter((item) => this.isEUmember(item))
      .map((item) => this.convertToDropItemFormat(item));
  }

  public getNotEuCountries(array) {
    return array
      .filter((item) => !this.isEUmember(item))
      .sort((a, b) => this.sf.sortByObjectPropertyValue(a, b, "name"))
      .map((item) => this.convertToDropItemFormat(item));
  }

  public getCountries(): Observable<any[]> {
    return this.client.get<any[]>(
      this.as.getCurentServer(ServerTypes.claims) + requests.countries,
      {
        headers: this.as.getHeaders(ServerTypes.claims, 0),
      }
    );
  }

  public getLanguages(): Observable<any[]> {
    return this.client.get<any[]>(
      this.as.getCurentServer(ServerTypes.uam2) + requests.languages,
      {
        headers: this.as.getHeaders(ServerTypes.uam2, 0),
      }
    );
  }

  public getCurrencies(): Observable<any[]> {
    return this.client.get<any[]>(
      this.as.getCurentServer(ServerTypes.uam2) + requests.currencies,
      {
        headers: this.as.getHeaders(ServerTypes.uam2, 0),
      }
    );
  }

  public selectedEUCountry(): DropdownSelectedItemTypes {
    const country = this.storage.getSessionStorageItem(AppStorageEnum.country);
    return country
      ? countriesEU.some(
          (item) => item.id.toUpperCase() === country.toUpperCase()
        )
        ? DropdownSelectedItemTypes.flag
        : DropdownSelectedItemTypes.countryCode
      : DropdownSelectedItemTypes.flag;
  }
}
