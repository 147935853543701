import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { Router } from '@angular/router';
import { LanguagesService } from '@frontend/shared/services';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { setCurrentStep } from '../+state/exemption.actions';
import { ExemptionPartialState } from '../+state/exemption.reducer';
import {
  getCompletedSteps,
  getCurrentStep,
} from '../+state/exemption.selectors';
import { ExemptionConfigurationService } from '../services/exemption-configuration.service';

@Component({
  selector: 'frontend-exemption-layout',
  templateUrl: './exemption-layout.component.html',
  styleUrls: ['./exemption-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExemptionLayoutComponent implements OnInit {
  steps: Array<{ title: string; path: string; stepIndex: number }> = [
    { title: 'Fill personal data', path: 'step1', stepIndex: 1 },
    { title: 'Fill vehicle data', path: 'step2', stepIndex: 2 },
    { title: 'Add documents', path: 'step3', stepIndex: 3 },
    { title: 'Finish', path: 'step4', stepIndex: 4 },
  ];

  completedSteps$: Observable<number[]>;
  currentStep$: Observable<number>;
  exemptionRouteName: string;
  constructor(
    private store: Store<ExemptionPartialState>,
    private exemptionConfigurationService: ExemptionConfigurationService
  ) {
    this.completedSteps$ = this.store.pipe(select(getCompletedSteps));
    this.currentStep$ = this.store.pipe(select(getCurrentStep));
  }

  onCurrentStepChange(currentStep: number) {
    this.store.dispatch(
      setCurrentStep({
        currentStep,
      })
    );
  }

  ngOnInit() {
    this.exemptionRouteName = this.exemptionConfigurationService.exemptionRouteName;
  }
}
