<frontend-card-page>
  <frontend-form [formGroup]="form" content fxLayout="column">
    <frontend-radio-input
      *ngIf="ukResidentOptionEnabled"
      [formControl]="isUkResidentControl"
      fxFlex="100"
      [radios]="[
        {
          id: 'ukResident',
          label: 'UK_RESIDENT',
          value: true
        },
        {
          id: 'nonUkResident',
          label: 'NON_UK_RESIDENT',
          value: false
        }
      ]"
    >
    </frontend-radio-input>

    <frontend-form-group
      *ngIf="!isUkResidentControl.value || !ukResidentOptionEnabled"
    >
      <frontend-label for="country">
        {{ 'COUNTRY' | translate }}
      </frontend-label>
      <frontend-select
        id="country"
        formControlName="country"
        [options]="countriesOptions"
      >
      </frontend-select>
    </frontend-form-group>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
      fxFlex="100"
      fxLayoutGap="2%"
      class="step2-form-row"
    >
      <frontend-form-group fxFlex="49">
        <frontend-label for="registration-number">
          {{ 'REGISTRATION_NUMBER' | translate }}
        </frontend-label>
        <frontend-text-input
          type="text"
          id="registration-number"
          formControlName="registrationNumber"
          [markWithError]="form.errors?.dePlateNumber"
        >
        </frontend-text-input>
        <frontend-error-text *ngIf="form.errors?.dePlateNumber">
          {{ 'INVALID_GERMAN_NUMBERPLATE_SEPARATOR' }}
        </frontend-error-text>
      </frontend-form-group>
      <frontend-form-group>
        <frontend-label for="euro-group">
          {{ 'EXEMPTION_EURO_GROUP' | translate }}
        </frontend-label>
        <frontend-select
          id="euro-group"
          formControlName="euroGroup"
          [options]="euroGroupOptions"
        >
        </frontend-select>
      </frontend-form-group>
    </div>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
      fxFlex="100"
      fxLayoutGap="2%"
      class="step2-form-row"
    >
      <frontend-form-group>
        <frontend-label for="make">
          {{ 'MAKE' | translate }}
        </frontend-label>
        <frontend-text-input
          type="text"
          id="make"
          formControlName="make"
        ></frontend-text-input>
      </frontend-form-group>
      <frontend-form-group>
        <frontend-label for="model">
          {{ 'MODEL' | translate }}
        </frontend-label>
        <frontend-text-input
          type="text"
          id="model"
          formControlName="model"
        ></frontend-text-input>
      </frontend-form-group>
    </div>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
      fxFlex="100"
      fxLayoutGap="2%"
      class="step2-form-row"
    >
      <frontend-form-group>
        <frontend-label for="length">
          {{ 'EXEMPTION_LENGHT' | translate }}
        </frontend-label>
        <frontend-text-input
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          id="length"
          min="0"
          formControlName="length"
        ></frontend-text-input>
        <div
          class="validation-error"
          *ngIf="!isVehicleLenValid">
          {{ 'VEHICLE_LENGTH_VALID_MSG' | translate: { maxLength: vehicleValidator[vehicleValidIdx].maxLength, class: vehicleValidator[vehicleValidIdx].class } }}
        </div>
      </frontend-form-group>
      <frontend-form-group>
        <frontend-label for="weight">
          {{ 'EXEMPTION_WEIGHT' | translate }}
        </frontend-label>
        <frontend-text-input
          id="weight"
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          min="0"
          formControlName="weight"
        ></frontend-text-input>
        <div
          class="validation-error"
          *ngIf="!isVehicleWgtValid">
          {{ 'VEHICLE_WEIGHT_VALID_MSG' | translate: { maxWeight: vehicleValidator[vehicleValidIdx].maxWeight, class: vehicleValidator[vehicleValidIdx].class } }}
        </div>
      </frontend-form-group>
    </div>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
      fxFlex="100"
      fxLayoutGap="2%"
      class="step2-form-row"
    >
      <frontend-form-group>
        <frontend-label for="emission-class">
          {{ 'EMISSION_CLASS' | translate }}
        </frontend-label>
        <frontend-select
          id="emission-class"
          formControlName="emissionClass"
          [options]="emissionClassOptions"
        >
        </frontend-select>
      </frontend-form-group>
      <frontend-form-group>
        <frontend-label for="fuel-type">
          {{ 'FUEL_TYPE' | translate }}
        </frontend-label>
        <frontend-select
          id="fuel-type"
          formControlName="fuelType"
          [options]="fuelTypeOptions"
        >
        </frontend-select>
      </frontend-form-group>
    </div>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
      fxFlex="100"
      fxLayoutGap="2%"
      class="step2-form-row"
    >
      <frontend-form-group>
        <frontend-checkbox-input
          id="hasParticalFilter"
          formControlName="hasParticalFilter"
        >
          {{ 'EXEMPTION_PARTICULFILTER' | translate }}
        </frontend-checkbox-input>
      </frontend-form-group>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="space-between start"
      fxFlex="100"
      class="step2-form-row"
    >
      <frontend-label>
        {{ 'VEHICLE_REGISTRATION_DATE' | translate }}
      </frontend-label>
      <frontend-form-group>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between start"
          fxLayoutGap="1%"
          class="step2-form-row"
          id="date-form-row"
          ngClass.gt-sm="small"
          ngClass.lt-md="small"
        >
          <frontend-form-group fxFlex="25">
            <frontend-label for="registration-date-day">
              {{ 'EXEMPTION_DAY' | translate }}
            </frontend-label>
            <frontend-text-input
              id="registration-date-day"
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              formControlName="registrationDateDay"
              maxLength="2"
              [min]="dayMin"
              [max]="dayMax"
            ></frontend-text-input>
          </frontend-form-group>
          <frontend-form-group fxFlex="25">
            <frontend-label for="registration-date-month">
              {{ 'EXEMPTION_MONTH' | translate }}
            </frontend-label>
            <frontend-text-input
              id="registration-date-month"
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              formControlName="registrationDateMonth"
              maxLength="2"
              [min]="monthMin"
              [max]="monthMax"
            >
            </frontend-text-input>
          </frontend-form-group>
          <frontend-form-group id="year-form-group" fxFlex="50">
            <frontend-label for="registration-date-year">
              {{ 'EXEMPTION_YEAR' | translate }}
            </frontend-label>
            <frontend-text-input
              id="registration-date-year"
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              formControlName="registrationDateYear"
              maxLength="4"
              [min]="yearMin"
            ></frontend-text-input>
          </frontend-form-group>
        </div>
      </frontend-form-group>
    </div>
    <frontend-button
      [disabled]="!form.valid"
      (click)="proceedClick()"
      type="button"
    >
      {{ 'EXEMPTION_NEXT' | translate }}
    </frontend-button>
  </frontend-form>
</frontend-card-page>
