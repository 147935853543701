import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsightsService } from '@frontend/shared/interfaces';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private appInsights: ApplicationInsightsService,
  ) {}

  public handleError(error: any): void {
    const chunkFailedMessage = /^.*Loading chunk .* failed/;
    const scrollRestorationMessage = /Cannot set property scrollRestoration/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
      return;
    }
    if (scrollRestorationMessage.test(error.message)) {
      return;
    }
    console.error('global-error-handler:');
    console.error(error);
    this.appInsights.trackError(error);
  }
}
