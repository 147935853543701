<frontend-themed-layout
  class="exemption-layout"
>
  <frontend-stepper
    [steps]="steps"
    [routeKey]="exemptionRouteName"
    [completedStepsArr]="completedSteps$ | async"
    [currentStep]="currentStep$ | async"
    (currentStepChange)="onCurrentStepChange($event)"
  >
    <router-outlet></router-outlet>
  </frontend-stepper>
</frontend-themed-layout>
