import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import {  MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';


import { FooterComponent } from '../components/footer/footer.component';

import { LayoutComponent } from '../components/layout/layout.component';
import { InnerSharedModule } from '../inner-shared/inner-shared.module';
import { CustomSidebarComponent } from './custom-sidebar/custom-sidebar.component';
import { IntroImageComponent } from './intro-image/intro-image.component';
import { EnvironmentService } from '@frontend/shared/services';
import { HeaderFooterLayoutComponent } from './header-footer-layout/header-footer-layout.component';
import { EpassHeaderComponent } from './header/epass-header.component';
import { HttpTranslateFactory } from '@frontend/shared/helpers';

const COMPONENTS = [
  FooterComponent,
  LayoutComponent,
  IntroImageComponent,
  CustomSidebarComponent,
  HeaderFooterLayoutComponent,
  EpassHeaderComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    InnerSharedModule,
    NgbModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    CommonModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        deps: [HttpClient, EnvironmentService],
        provide: TranslateLoader,
        useFactory: HttpTranslateFactory,
      },
    }),
  ],
})
export class ComponentsModule { }
