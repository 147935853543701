import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UnsubscriberComponent } from '../../../../components/unsubscriber.component';
import { DropProps } from '../../../../components/drop-props';
import { InnerDropdownBaseComponent } from '../../dropdown-base/dropdown-base.component';
import { DropdownSelectedItemTypes } from '../../dropdown-selected-item-types.enum';
import { InnerDropService } from '../../inner-drop.service';
import { LayoutService } from '../../../../services';

@Component({
  selector: 'pp-additional-drop-items',
  templateUrl: './additional-drop-items.component.html',
  styleUrls: ['./additional-drop-items.component.scss'],
})
export class AdditionalDropItemsComponent
  extends UnsubscriberComponent
  implements OnInit {
  @Input() disabled: boolean = false;
  @Input() public mainDropProps: DropProps;
  @Output() public selection: EventEmitter<string> = new EventEmitter<string>();
  public showSecondaryList = false;
  private winWidth: number;
  private countriesReady = false;
  @ViewChild('dropdown', { static: true })
  public dropWrap: InnerDropdownBaseComponent;
  @ViewChild('overlay', { read: ElementRef, static: true })
  public drop: ElementRef;

  constructor(
    private dropService: InnerDropService,
    private layoutService: LayoutService,
  ) {
    super();
  }

  public ngOnInit() {
    this.subscriptions.add(
      this.dropService.extraItems$.subscribe((items) => {
        this.mainDropProps.additionalDropItems = items;
      })
    );

    this.subscriptions.add(
      this.layoutService.winWidth$.subscribe((width) => (this.winWidth = width))
    );
  }

  public stateHandler() {
    this.showSecondaryList = false;
    setTimeout(() => this.scrollToBottom(), 0);
  }

  public toggleSecondaryList(e) {
    this.showSecondaryList = !this.showSecondaryList;
    e.stopImmediatePropagation();
  }

  public scrollToBottom() {
    const footerHeight = document.getElementById('footer')?.offsetHeight ?? 0;
    const scrollHeight =
      document.documentElement.offsetHeight >
      document.documentElement.scrollHeight
        ? 0
        : document.documentElement.scrollHeight -
          document.documentElement.offsetHeight +
          footerHeight;
    // const scrollConfig: ScrollToConfigOptions = {
    //   offsetMap: new Map().set(575, scrollHeight),
    //   duration: 200,
    // } as ScrollToConfigOptions;
    // this.scrollToService.scrollTo(scrollConfig);
  }

  public handleSelection(selection) {
    this.dropService.updateSelectedItemType(
      this.showSecondaryList
        ? DropdownSelectedItemTypes.countryCode
        : DropdownSelectedItemTypes.flag
    );
    this.dropService.updateSelectionToDisplay(selection);
    this.selection.emit(selection);
    this.dropWrap.dropdown.close();
  }

  public handleSecondaryCountries(countries) {
    this.dropService.updateExtraItems(
      this.dropService.getNotEuCountries(countries)
    );
    if (!this.countriesReady) {
      this.scrollToBottom();
    }
    this.countriesReady = true;
  }
}
