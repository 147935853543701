<div [formGroup]="formGroup">
  <div class="row align-items-end no-gutters">
    <div class="col-6 static-col">
      <label for="start">{{ 'PERIOD' | translate }}</label>
      <input type="text" class="form-control" formControlName="dateStart" ppDateValAccessor id="start" #start
        (focus)="start.blur()">
    </div>
    <div class="col-6 static-col">
      <div class="input-holder">
        <input type="text" class="form-control" formControlName="dateEnd" ppDateValAccessor id="end" #end
          (focus)="end.blur()" title="end" autocomplete="end">
      </div>
    </div>
    <div class="col-12 static-col">
      <ngb-datepicker #d (dateSelect)="onDateSelection($event)" [dayTemplate]="t" class="form-control datepicker-border datepicker-custom" title="account period"
          [displayMonths]="2" [navigation]="'arrows'" [showWeekNumbers]="false" [outsideDays]="'collapsed'" [markDisabled]="isDateDisabled">
        </ngb-datepicker>
    </div>
    <div class="col-12 static-col mt-2" *ngIf="showTime">
      <div class="card-body d-flex pt-0 justify-content-around align-items-center">
        <ngb-timepicker [formControlName]="'timeStart'" [size]="'small'"></ngb-timepicker>
        <ngb-timepicker [formControlName]="'timeEnd'" [size]="'small'"></ngb-timepicker>
      </div>
    </div>
  </div>
</div>

<ng-template #t let-date let-focused="focused" let-disabled="disabled">
  <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)" [class.text-muted]="disabled"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>
