export enum ServerTypes {
  claims = 'claims',
  auth = 'auth',
  uam = 'uam',
  uam2 = 'uam2',
}

export const servers = [
  { type: ServerTypes.claims, url: ['https://front-gtw.epass24.com', 'https://api1.parkiapay.com', 'https://api2.parkiapay.com'] },
  { type: ServerTypes.auth, url: ['https://front-gtw.epass24.com', 'https://api1.parkiapay.com', 'https://api2.parkiapay.com'] },
  { type: ServerTypes.uam, url: ['https://app-uam1-api.azurewebsites.net', 'https://app-uam1-api.azurewebsites.net'] },
  { type: ServerTypes.uam2, url: ['https://api-account.epass24.com'] },
];

export const defaultServerConfig = [
  { type: ServerTypes.claims, index: 0 },
  { type: ServerTypes.auth, index: 0 },
  { type: ServerTypes.uam, index: 0 },
  { type: ServerTypes.uam2, index: 0 },
];

export const defaultServer = ServerTypes.claims;
