import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ExemptionPartialState,
  EXEMPTION_FEATURE_KEY,
  State,
} from './exemption.reducer';

// Lookup the 'Exemption' feature state managed by NgRx
export const getExemptionState = createFeatureSelector<
  ExemptionPartialState,
  State
>(EXEMPTION_FEATURE_KEY);

export const getPersonalData = createSelector(
  getExemptionState,
  (state: State) => state.personalData
);

export const getVehicleData = createSelector(
  getExemptionState,
  (state: State) => state.vehicleData
);

export const getImagesData = createSelector(
  getExemptionState,
  (state: State) => state.imagesData
);

export const getCompletedSteps = createSelector(
  getExemptionState,
  (state: State) => state.completedSteps
);

export const getCurrentStep = createSelector(
  getExemptionState,
  (state: State) => state.currentStep
);

export const getIsLoading = createSelector(
  getExemptionState,
  (state: State) => state.isLoading
);
