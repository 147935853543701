import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'epass-header-footer-layout',
  templateUrl: './header-footer-layout.component.html',
  styleUrls: ['./header-footer-layout.component.scss']
})
export class HeaderFooterLayoutComponent implements OnInit {
  @Input() contentGridColumns: string = '1fr 3fr 1fr';
  @Input() headerHeight: string = '92px';

  constructor() { }

  ngOnInit(): void {
  }

}
