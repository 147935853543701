import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LayoutService } from '../../services';
import { UnsubscriberComponent } from '../unsubscriber.component';
import { AuthService } from '../../services/auth.service';
import { SidebarLink } from './sidebar-link';
import { LanguagesService } from '@frontend/shared/services';

@Component({
  selector: 'pp-custom-sidebar',
  templateUrl: './custom-sidebar.component.html',
})
export class CustomSidebarComponent
  extends UnsubscriberComponent
  implements OnInit {
  @Input() public title: string;
  @Input() public links: SidebarLink[];
  public isMobile: boolean;

  constructor(
    private auth: AuthService,
    private router: Router,
    private layoutService: LayoutService,
    private langService: LanguagesService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.layoutService.winWidth$.subscribe(
        (width) => (this.isMobile = width <= 992)
      )
    );
  }

  public getLink(link): any {
    this.router.navigate([
      this.langService.getCurrentLang().id,
      'account',
      link,
    ]);
  }  

  public isActive(link): boolean {
    return this.router.url.indexOf(link) != -1;
  }

  public logout() {
    this.subscriptions.add(
      this.auth.logout().subscribe(() => {
        this.router.navigate([
          this.langService.getCurrentLang().id,
          'account',
          'login',
        ]);
      })
    );
  }
}
