import { Component } from '@angular/core';
import {InnerDropService} from '../../inner-drop.service';
import {SelectionToDisplaySubscriber} from '../selection-to-display-subscriber';

@Component({
  selector: 'pp-selected-item-country-code',
  templateUrl: './selected-item-country-code.component.html',
  styleUrls: ['./selected-item-country-code.component.scss'],
})
export class SelectedItemCountryCodeComponent extends SelectionToDisplaySubscriber {

  constructor(
    private ds: InnerDropService,
  ) {
    super(ds);
  }

}
